import React, { useState, useEffect } from 'react';
import {
  CButton,
  CCol,
  CRow,
  CInput,
  CLabel,
  CSelect,
  CFormGroup,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalFooter,
  CModalBody,
  CTextarea,
} from '@coreui/react';

import 'react-circular-progressbar/dist/styles.css';
import './ProjectDetails.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { FaArchive, FaPen, FaTimes,FaFileExcel, FaFilePdf, FaUndoAlt } from 'react-icons/fa';

import { getReport, saveDilyReport, getDetails, editReport, changeStatus, saveSwa, EditSwa } from 'src/request/Reports';

let data = [
  {
    report_id: 1,
    date: '01-02-2022',
    submitted: 'jose Rizal',
    last_modified: '01-02-2022,4:38pm',
  },
  {
    report_id: 2,
    date: '01-02-2022',
    submitted: 'jose Rizal',
    last_modified: '01-02-2022,4:38pm',
  },
  {
    report_id: 3,
    date: '01-02-2022',
    submitted: 'jose Rizal',
    last_modified: '01-02-2022,4:38pm',
  },
];

const Swa = (props) => {
  const [swaData, setSwaData] = useState([]);
  const [file, setFile] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [cond, setCond] = useState('Add');
  const [reportLogId, setReportLogId] = useState(-1);
  const [remove, setRemove] = useState([]);
  const [fileLength, setFileLength] = useState(0);
  const [status, setStatus] = useState(0);
  const [progressReport, setProgressReport] = useState([
    {
      report_id: -1,
      prepared_by: '',
      billing_percentage: '',
      remarks: '',
    },
  ]);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    getSwa(status);
  }, []);

  const add = () => {
    let arr = [...progressReport];
    arr.push({
      prepared_by: '',
      billing_percentage: '',
    });

    setProgressReport(arr);
  };

  const getSwa = (status) => {
    getReport(props.id, 'swa', status).then((res) => {
      if (res.data.status == 1) {
        setSwaData(res.data.response);
      } else {
        Swal.fire({
          icon: 'error',
          title: res.data.response,
          text: res.data.description,
          confirmButtonColor: '#23303D',
          // footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    });
  };

  const save = () => {
    console.log(progressReport, 'zxczxc');
    saveSwa(file, progressReport, props.id).then((res) => {
      if (res.data.status == 1) {
        Swal.fire({
          // position: 'top-end',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500,
        });
        AddNew();
        getSwa(status);
      } else {
        Swal.fire({
          icon: 'error',
          title: res.data.response,
          text: res.data.description,
          confirmButtonColor: '#23303D',
          // footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    });
  };

  const edit = () => {
    EditSwa(file, progressReport, props.id, remove).then((res) => {
      if (res.data.status == 1) {
        Swal.fire({
          // position: 'top-end',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500,
        });

        AddNew();
        getSwa(status);
      } else {
        Swal.fire({
          icon: 'error',
          title: res.data.response,
          text: res.data.description,
          confirmButtonColor: '#23303D',
          // footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    });
  };

  const getReportById = (report_log_id) => {
    setReportLogId(report_log_id);
    getDetails('swa', report_log_id).then((res) => {
      if (res.data.status == 1) {
        setProgressReport([
          {
            report_id: res.data.response.report_id,
            billing_percentage: res.data.response.billing_percentage,
            prepared_by: res.data.response.prepared_by,
            remarks: res.data.response.remarks,
          },
        ]);

        console.log(progressReport);
        setFileName(res.data.response.files);
        setFileLength(res.data.response.files.length);

        setModal(!modal);
      } else {
        Swal.fire({
          icon: 'error',
          title: res.data.response,
          text: res.data.description,
          confirmButtonColor: '#23303D',
          // footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    });
  };

  const AddNew = () => {
    setFile([]);
    setFileName([]);
    setModal(!modal);
    setCond('Add');
    setRemove([]);
    setProgressReport([
      {
        report_id: -1,
        prepared_by: '',
        billing_percentage: '',
        remarks: '',
      },
    ]);
  };
  const dateChange = (e, Index) => {
    let arr = [...progressReport];

    arr[Index].date_uploaded = e.target.value;

    setProgressReport(arr);
  };

  const prepareChange = (e, Index) => {
    let arr = [...progressReport];

    arr[Index].prepared_by = e.target.value;

    setProgressReport(arr);
  };
  const percentageChange = (e, Index) => {
    let arr = [...progressReport];

    arr[Index].billing_percentage = e.target.value;

    setProgressReport(arr);
  };

  const textareaChange = (e, Index) => {
    let arr = [...progressReport];
    arr[Index].remarks = e.target.value;
    setProgressReport(arr);
  };

  const upload = (e) => {
    let new_arr = [];
    let arr = [...file];
    let arr_docs = [...fileName];

    new_arr.push(e.target.files);
    for (var i in e.target.files) {
      arr.push(e.target.files[i]);
    }

    setFile(arr);

    for (let i = 0; i < new_arr[0].length; i++) {
      arr_docs.push({ name: new_arr[0][i]['name'], link: '' });
    }

    setFileName(arr_docs);
  };

  const removeFile = (name) => {
    let arr = [...fileName];
    let arrFile = [...file];
    let arrRemove = [...remove];
    let Index = arr.findIndex((a) => a.name == name);
    arr.splice(Index, 1);
    if (arrFile.length != 0) {
      let removeMe = fileLength - 1;
      arrFile.splice(removeMe, 1);
    }

    console.log(arr);
    console.log(arrFile);
    console.log(arrRemove);

    arrRemove.push(name);
    setFileName(arr);
    setFile(arrFile);
    setRemove(arrRemove);
  };

  const changeStatusReport = (report_log_id, method) => {
    Swal.fire({
      icon: 'question',
      title: "<span style='color:#B42A19;'>Are you sure</span>",
      html: "<p style='font-weight:bold'>If you proceed,you will archived all your <br> data.Are you sure you want to <br> achive this report?</p>",
      // icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#39f',
      cancelButtonColor: '#B42A19',
      confirmButtonText: 'Proceed',
    }).then((result) => {
      if (result.isConfirmed) {
        changeStatus(report_log_id, method).then((res) => {
          if (res.data.status == 1) {
            Swal.fire({
              // position: 'top-end',
              icon: 'success',
              title: 'Your work has been archived',
              showConfirmButton: false,
              timer: 1500,
            });

            getSwa(status);
          } else {
            Swal.fire({
              icon: 'error',
              title: res.data.response,
              text: res.data.description,
              confirmButtonColor: '#23303D',
              // footer: '<a href="">Why do I have this issue?</a>',
            });
          }
        });
      }
    });
  };

  const dataTable = swaData.map((data, Index) => {
    return (
      <tr key={Index}>
        <td>
          <CButton color="link" style={{ color: '#23303d' }} onClick={() => getReportById(data.report_log_id, setCond('View'))}>
            {data.date}
          </CButton>
        </td>
        <td>{data.submitted}</td>
        <td>{data.last_modified}</td>
        <td>
          {status == 0 && <FaPen onClick={() => getReportById(data.report_log_id, setCond('Edit'))} />}

          {status == 0 ? <FaArchive style={{ marginLeft: '5px' }} onClick={() => changeStatusReport(data.report_log_id, 'DELETE')} /> : <FaUndoAlt style={{ marginLeft: '5px' }} onClick={() => changeStatusReport(data.report_log_id, 'PUT')} />}
        </td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  });

  const AddFormTable = progressReport.map((data, Index) => {
    return (
      <tr key={Index}>
        <td>
          <CInput type="text" value={data.prepared_by} onChange={(e) => prepareChange(e, Index)} disabled={cond == 'View'} />
        </td>
        <td>
          <CInput type="number" value={data.billing_percentage} onChange={(e) => percentageChange(e, Index)} disabled={cond == 'View'} />
        </td>
        <td>
          <CTextarea value={data.remarks} onChange={(e) => textareaChange(e, Index)} disabled={cond == 'View'} />
        </td>
      </tr>
    );
  });

  const Files = fileName.map((file, Index) => {
    let extention = file.name.split('.').pop();
    return (
      <CCol md="3" key={Index} style={{ textAlign: 'center' }}>
        <i className="div-icon" onClick={() => removeFile(file.name)}>
          <FaTimes style={{ height: '20px', width: '20px' }} />
        </i>

        {extention == 'pdf' ? <FaFilePdf style={{ width: '50px', height: '50px' }} /> : <FaFileExcel style={{ width: '50px', height: '50px' }} />}

        <CCol md="12">
          <a href={file.link}>
            <span style={{ fontSize: '12px', color: '#23303D' }}>{file.name}</span>
          </a>
        </CCol>
      </CCol>
    );
  });

  return (
    <>
      <CRow>
        <CCol xs="12" sm="12" md="12" lg="12" xl="9" style={{ marginTop: '20px' }}>
          <CCol xs="12" sm="12" md="12" lg="12" xl="12">
            <CFormGroup row style={{ marginLeft: '2px' }}>
              <CLabel>Filter View</CLabel>

              <CCol xs="12" sm="12" md="3" lg="3" className="margin-mobile float-left">
                <CSelect name="select" id="select" size="sm" onChange={(e) => getSwa(e.target.value, setStatus(e.target.value))} value={status}>
                  <option disabled value="">
                    Status
                  </option>

                  <option value={0}>Active</option>
                  <option value={1}>Archived</option>
                </CSelect>
              </CCol>
            </CFormGroup>
          </CCol>
          <table className="table">
            <thead className="thead-light">
              <tr>
                <th>Date</th>
                <th>Submitted by</th>
                <th>Last Modified</th>
                <th>Action</th>
                <th></th>
                <th></th>
                <th>
                  {status == 0 && (
                    <CButton size="sm" color="aimhi-primary" block onClick={() => AddNew()}>
                      Add New
                    </CButton>
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {dataTable}
              {swaData.length == 0 && (
                <td colSpan={4} style={{ textAlign: 'center' }}>
                  No Data Available
                </td>
              )}
            </tbody>
          </table>
        </CCol>
      </CRow>
      <CModal show={modal} onClose={() => setModal(!modal)} style={{ borderRadius: '5px' }} size="lg">
        <CModalHeader closeButton>
          <CModalTitle>Statement of Work Accomplished</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CCol md="12" sm="12" lg="12" xl="12">
            <table className="table">
              <thead>
                <tr>
                  <th>Prepared By</th>
                  <th>Progress Billing Percentage</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>{AddFormTable}</tbody>
            </table>
            <CCol md="12">
              <CRow>{Files}</CRow>
            </CCol>
            <CCol md="12" sm="12" lg="3" xl="3">
              {cond != 'View' && (
                <label className="upload-file-swa" style={{ marginTop: '10px' }}>
                  <input type="file" name="file" id="file-input" multiple onChange={upload} className="upload-button upload-file-swa" accept=".xlsx,.xls,.csv,.pdf" />

                  <span>
                    <u>Attach File/s</u>
                  </span>
                </label>
              )}
            </CCol>
          </CCol>
        </CModalBody>
        <CModalFooter style={{ borderColor: 'white' }}>
          <CCol md="4" sm="4" lg="4" xl="4">
            {cond == 'Add' && (
              <CButton block color="aimhi-textlight-neutral" size="sm" variant="outline" onClick={save}>
                Submit
              </CButton>
            )}

            {cond == 'Edit' && (
              <CButton block color="aimhi-textlight-neutral" size="sm" variant="outline" onClick={edit}>
                Update
              </CButton>
            )}
          </CCol>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default Swa;
