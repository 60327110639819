import React, { useState, useEffect } from 'react';
import { CButton, CCard, CCardBody, CCol, CRow, CCollapse, CBadge, CInput, CProgress, CProgressBar, CTextarea, CSpinner, CLabel, CSelect } from '@coreui/react';

import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './ProjectDetails.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import IP from '../../StoreGlobal/IP';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FaTrashAlt } from 'react-icons/fa';

import { getReport, saveDilyReport, getDetails, editReport, changeStatus, getSowlistWeekly, saveWeekly } from 'src/request/Reports';
import { DownloadReportWeekly } from 'src/request/Projects';
import { getFeatures } from 'src/request/Features';
import { findAllowedFeature } from 'src/utils/findAllowedFeature';
import { useHistory } from 'react-router-dom';
import Loading from '../aimhi-widgets/Loading';

let data_week = {
  date: '03/06/2023 - 03/17/2023',
  data: [
    {
      report_id: 1,
      work_item: 'Glass work',
      under_budget: 1,
      delayed: 1,
      total_cost: 100,
      total_budget: 200,
      item: [
        {
          work_item: 'Glass work',
          target_date: '03/07/2023',
          actual_date: '03/03/2023',
          comments: '',
        },
      ],
    },
    {
      report_id: 2,
      work_item: 'Glass work',
      under_budget: 1,
      delayed: 1,
      total_cost: 100,
      total_budget: 200,
      item: [
        {
          work_item: 'Glass work',
          target_date: '03/07/2023',
          actual_date: '03/03/2023',
          comments: '',
        },
      ],
    },
    {
      report_id: 3,
      work_item: 'Glass work',
      under_budget: 1,
      delayed: 1,
      total_cost: 100,
      total_budget: 200,
      date: '03/06/2023 - 03/17/2023',
      item: [
        {
          work_item: 'Glass work',
          target_date: '03/07/2023',
          actual_date: '03/03/2023',
          comments: '',
        },
      ],
    },
  ],
};

const Weekly = (props) => {
  let user_id = parseInt(localStorage.getItem('user_id'));
  const features = useSelector((state) => state.features);
  const [weeklyReport, setWeeklyReport] = useState([]);
  const [currentSow, setCurrentSow] = useState(false);
  const [currentTarget, setCurrentTarget] = useState(false);
  const [currentActual, setCurrentActual] = useState(false);
  const [currentComment, setCurrentComment] = useState(false);
  const [upcommingSow, setUpcommingSow] = useState(false);
  const [upcommingTarget, setUpcommingTarget] = useState(false);
  const [upcommingComment, setUpcommingComment] = useState(false);
  const [remarkReq, setRemarkReq] = useState(false);
  const [commentReq, setCommentReq] = useState(false);
  const [reportId, setReportId] = useState(-1);
  const [reportLogId, setReportLogId] = useState(-1);
  const [cond, setCond] = useState('Add');
  const [status, setStatus] = useState(0);
  const [collapse, setCollapse] = useState(true);
  const [workItem, setWorkItem] = useState([]);
  const [date, setDate] = useState('');
  const [toggle, setToggle] = useState([]);
  const [projectStart, setprojectStart] = useState('');
  const [projectEnd, setprojectEnd] = useState('');
  let accessToken = localStorage.getItem('token');
  let permission = useSelector((state) => state.permission);
  const [projectId, setprojectId] = useState('');
  const company_id = parseInt(localStorage.getItem('company_id'));
  const [taskItem, settaskItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadableReportAllowed, setIsDownloadableReportAllowed] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(1);
  const history = useHistory();
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [current, setCurrent] = useState([
    {
      sow: '',
      target_date: '',
      actual_date: '',
      comments: '',
    },
  ]);
  const [upcomming, setUpComming] = useState([
    {
      sow: '',
      target_date: '',
      actual_date: '',
      comments: '',
    },
  ]);

  const [remarks, setRemarks] = useState([
    {
      description: '',
      comments: '',
    },
  ]);
  const [modal, setModal] = useState(false);
  useEffect(() => {
    // setWeeklyReport(data_week.data);
    // setDate(data_week.date);
    // getWeekly(0);
    // getWorkItem();
    getWeekly(0);
    // scopeofwork(props.id, props.type);
  }, []);

  useEffect(() => {
    if (features.length > 0) {
      setIsDownloadableReportAllowed(findAllowedFeature(features, 5));
    }
  }, [features]);

  const getWorkItem = () => {
    getSowlistWeekly(props.id).then((res) => {
      if (res.data.status == 200) {
        let arrWork = res.data.response;

        arrWork.push({
          id: -1,
          value: 'Others',
          start_date: '',
        });
        setWorkItem(arrWork);
        console.log(arrWork);
      }
    });
  };

  let weekly = [];

  const getWeekly = (status) => {
    setIsDataLoading(true);
    getReport(props.id, 'weekly', status).then((res) => {
      // console.log(res, 'weekly report');
      if (res.status == 200) {
        setIsDataLoading(false);
        setWeeklyReport(res.data.response.data);
        weekly = res.data.response.data;
        setDate(res.data.response.date);

        // setWeeklyReport(res.data.response);
        // setWeeklyReport(data_week);
      } else {
        Swal.fire({
          icon: 'error',
          title: res.data.response,
          text: res.data.description,
          confirmButtonColor: '#23303D',
          // footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    });
  };

  const scopeofwork = (project_id, project_type) => {
    axios
      .get(IP + `sow/details/${company_id}/${project_id}`, {
        // project_id,
        // project_type,
        // company_id: company_id,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        // settaskItem(res.data.response);
        console.log('weeklyPSOW', res.data.response);
        //setLoading(false);
        getWeekly(0);

        let data = res.data.response;
        if (data.length != 0) {
          let arr = [];
          data.forEach((phase) => {
            phase.Task.forEach((task) => {
              arr.push({
                phase: phase.Phase,
                ProgressPercentage: phase.ProgressPercentage,
                item: [
                  {
                    id: task.id,
                    sow_id: task.sow_id,
                    SOW: task.SOW,
                    Status: task.Status,
                  },
                ],
              });
            });
          });
          // setTasks(arr);
          console.log('arrweeklyPSOW1', arr);
          console.log('arrweeklyPSOW2', weekly);

          let arrShet = [];
          weekly.forEach((weeklyItem) => {
            weeklyItem.item.forEach((item) => {
              arrShet.push({
                psow_id: item.psow_id,
              });
            });
          });
          console.log('arrShet', arrShet);

          const includeShet = weekly.filter((weeklyItem) =>
            data.some(
              (arrItem) => arrItem.Phase === weeklyItem.phase,
              // arrItem.TASKS[0].id === weeklyItem.item[0].psow_id &&
              // arrItem.TASKS[0].sow_id === weeklyItem.item[0].sow_id &&
              // arrItem.TASKS[0].SOW === weeklyItem.item[0].work_item
            ),
          );
          console.log('arrweeklyPSOW3\n', includeShet);

          const includeNone = data.filter((arrItem) =>
            weekly.some(
              (weeklyItem) => weeklyItem.phase === arrItem.Phase,
              // weeklyItem.item[0].psow_id === arrItem.TASKS[0].id &&
              // weeklyItem.item[0].sow_id === arrItem.TASKS[0].sow_id &&
              // weeklyItem.item[0].work_item === arrItem.TASKS[0].SOW
            ),
          );

          console.log('arrweeklyPSOW4\n', includeNone);
        }
      })
      .catch((error) => {
        console.log('projectdetails/ProjectOverview.js', ' ', 'line 264', error);
      });
  };

  const SaveWeekly = () => {
    let data = {
      current_sow: current,
      upcoming_sow: upcomming,
      remarks: remarks,
    };
    saveDilyReport(data, 'weekly', props.id, user_id).then((res) => {
      if (res.data.status == 1) {
        Swal.fire({
          // position: 'top-end',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500,
        });
        getWeekly(status);
        setModal(!modal);
      } else {
        Swal.fire({
          icon: 'error',
          title: res.data.response,
          text: res.data.description,
          confirmButtonColor: '#23303D',
          // footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    });
  };

  const getReportById = (report_log_id) => {
    getDetails('weekly', report_log_id).then((res) => {
      if (res.data.status == 1) {
        console.log(res);
        setCurrent(res.data.response.current_sow);
        setUpComming(res.data.response.upcoming_sow);
        setRemarks(res.data.response.remarks);
        setReportId(res.data.response.report_id);
        setReportLogId(res.data.response.report_log_id);
        setCurrentSow(true);
        setCurrentTarget(true);
        setCurrentActual(true);
        setCurrentComment(true);
        setUpcommingSow(true);
        setUpcommingTarget(true);

        setUpcommingComment(true);
        setRemarkReq(true);
        setCommentReq(true);
        setModal(!modal);
      } else {
        Swal.fire({
          icon: 'error',
          title: res.data.response,
          text: res.data.description,
          confirmButtonColor: '#23303D',
          // footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    });
  };

  const updateWeekly = () => {
    let data = {
      report_id: reportId,
      report_log_id: reportLogId,
      current_sow: current,
      remarks: remarks,
      upcoming_sow: upcomming,
    };
    editReport(data, 'weekly').then((res) => {
      if (res.data.status == 1) {
        Swal.fire({
          // position: 'top-end',
          icon: 'success',
          title: 'Your work has been updated',
          showConfirmButton: false,
          timer: 1500,
        });
        getWeekly(status);
        setModal(!modal);
      } else {
        Swal.fire({
          icon: 'error',
          title: res.data.response,
          text: res.data.description,
          confirmButtonColor: '#23303D',
          // footer: '<a href="">Why do I have this issue?</a>',
        });
      }
    });
  };

  const changeStatusReport = (report_log_id, method) => {
    Swal.fire({
      icon: 'question',
      title: "<span style='color:#B42A19;'>Are you sure</span>",
      html: "<p style='font-weight:bold'>If you proceed,you will archived all your <br> data.Are you sure you want to <br> achive this report?</p>",
      showCancelButton: true,
      confirmButtonColor: '#39f',
      cancelButtonColor: '#B42A19',
      confirmButtonText: 'Proceed',
    }).then((result) => {
      if (result.isConfirmed) {
        changeStatus(report_log_id, method).then((res) => {
          if (res.data.status == 1) {
            Swal.fire({
              // position: 'top-end',
              icon: 'success',
              title: 'Your work has been archived',
              showConfirmButton: false,
              timer: 1500,
            });
            getWeekly(status);
          } else {
            Swal.fire({
              icon: 'error',
              title: res.data.response,
              text: res.data.description,
              confirmButtonColor: '#23303D',
              // footer: '<a href="">Why do I have this issue?</a>',
            });
          }
        });
      }
    });
  };

  const saveChanges = () => {
    let arr = [...weeklyReport];
    let newArr = [];

    for (let i = 0; i < arr.length; i++) {
      let newItem = [];
      console.log(arr[i]);
      console.log(arr[i].item);
      arr[i].date_range = date;
      arr[i].save_type = 'CW';
      for (let j = 0; j < arr[i].item.length; j++) {
        if (arr[i].item[j].edited) {
          // arr[i].item[j].actual_date = adjustDateFormat(arr[i].item[j].actual_date);
          // arr[i].item[j].target_date = adjustDateFormat(arr[i].item[j].target_date);
          arr[i].item[j].start_date = adjustDateFormat(arr[i].item[j].start_date);
          arr[i].item[j].end_date = adjustDateFormat(arr[i].item[j].end_date);
          arr[i].item[j].actual_start_date = adjustDateFormat(arr[i].item[j].actual_start_date);
          arr[i].item[j].actual_end_date = adjustDateFormat(arr[i].item[j].actual_end_date);
          arr[i].item[j].date_created = adjustDateFormat(arr[i].item[j].date_created);
          newItem.push(arr[i].item[j]);
        }

        if (arr[i].item[j].accomplishment_edited) {
          console.log('accomplishment_edited', arr[i].item[j]);
          arr[i].item[j].status = 0;
        } else {
          arr[i].item[j].accomplishment = 0;
        }
      }

      if (newItem.length > 0) {
        arr[i].item = newItem;
        newArr.push(arr[i]);
      }
    }

    console.log('newArr save', newArr);

    // arr.forEach((data) => {
    //   let newData = data.item.find(
    //     (a) => a["new"] == true || a["edited"] == true
    //   );
    //   if (typeof newData != "undefined") {
    //     newArr.push(data);
    //   }

    //   // let dataArr = data.item;
    //   // console.log(data.item);
    //   // let newData = dataArr.find(
    //   //   (a) => a["new"] == true || a["edited"] == true
    //   // );
    //   // if (typeof newData != "undefined") {
    //   //   newArr.push(newData);
    //   // }
    // });

    if (newArr.length > 0) {
      saveWeekly(props.id, newArr).then((res) => {
        console.log(res);
        if (res.data.status == 200) {
          Swal.fire({
            // position: 'top-end',
            icon: 'success',
            title: 'Your work has been saved!',
            showConfirmButton: false,
            timer: 1500,
          });
          getWeekly(0);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: res.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops..',
        text: 'No data to be save',
        confirmButtonColor: '#23303D',
        // footer: '<a href="">Why do I have this issue?</a>',
      });
    }
  };

  const removeRemarks = (Index) => {
    let arr = [...remarks];
    arr.splice(Index, 1);
    setRemarkReq(true);
    setCommentReq(true);

    setRemarks(arr);
  };

  const AddNew = (Index) => {
    let arr = [...weeklyReport];
    console.log(arr);

    arr[Index].item.push({
      psow_id: null,
      // report_id: null,
      phase_id: null,
      sow_id: null,
      work_item: '',
      target_date: '',
      actual_date: '',
      comments: '',
      new: true,
    });
    setWeeklyReport(arr);
  };

  const toggleShow = (value) => {
    const shownState = toggle.slice();
    const index = shownState.indexOf(value);

    if (index >= 0) {
      shownState.splice(index, 1);
      setToggle(shownState);
    } else {
      shownState.push(value);
      setToggle(shownState);
    }
  };

  const workItemChange = (e, dataIndex, itemIndex) => {
    let arr = [...weeklyReport];
    arr[dataIndex].item[itemIndex].work_item = e.target.value;

    setWeeklyReport(arr);
  };

  const adjustDateFormat = (strDate) => {
    if (strDate) {
      let a = new Date(strDate);
      let sm = `${a.getMonth() + 1}`.padStart(2, 0);
      let sd = `${a.getDate()}`.padStart(2, 0);
      let sy = a.getFullYear();
      let formatted = `${sm}/${sd}/${sy}`;
      return formatted;
    }

    return '';
  };

  // const targetDateChange = (e, dataIndex, itemIndex) => {
  //   let arr = [...weeklyReport];
  //   arr[dataIndex].item[itemIndex].target_date = e.target.value;
  //   arr[dataIndex].item[itemIndex].edited = true;
  //   setWeeklyReport(arr);
  // };

  const commentChange = (e, dataIndex, itemIndex) => {
    let arr = [...weeklyReport];
    arr[dataIndex].item[itemIndex].comments = e.target.value;
    arr[dataIndex].item[itemIndex]['edited'] = true;
    setWeeklyReport(arr);
  };

  // const actualDateChange = (e, dataIndex, itemIndex) => {
  //   let arr = [...weeklyReport];
  //   arr[dataIndex].item[itemIndex].actual_date = e.target.value;
  //   arr[dataIndex].item[itemIndex]['edited'] = true;
  //   setWeeklyReport(arr);
  // };

  const remove = (dataIndex, itemIndex) => {
    let arr = [...weeklyReport];

    arr[dataIndex].item.splice(itemIndex, 1);
    setWeeklyReport(arr);
  };

  // const changestartdate = (value, Index, dataIndex, category) => {
  //   const newArr = [...weeklyReport];

  //   if (category === 'target') {
  //     const formattedDate = value ? value.toLocaleDateString('en-US', {
  //       month: '2-digit',
  //       day: '2-digit',
  //       year: 'numeric'
  //     }) : null;

  //     newArr[dataIndex].item[Index].target_date = formattedDate;
  //     // console.log('After (target) update:', newArr);

  //   } else if (category === 'actual') {
  //     const formattedDate = value ? value.toLocaleDateString('en-US', {
  //       month: '2-digit',
  //       day: '2-digit',
  //       year: 'numeric'
  //     }) : null;

  //     newArr[dataIndex].item[Index].target_date = formattedDate;
  //     // console.log('After (actual) update:', newArr);
  //   }

  //   setWeeklyReport(newArr);
  // };

  useEffect(() => {
    setprojectId(props.id);
    dateformat(props.start, props.end);
  }, []);

  const dateformat = (start, end) => {
    let a = new Date(start);
    let b = new Date(end);

    let sm = a.getMonth() + 1;
    let sd = a.getDate();
    let sy = a.getFullYear();
    let date_start = sy + '-' + sm + '-' + sd;

    let em = b.getMonth() + 1;
    let ed = b.getDate();
    let ey = b.getFullYear();
    let date_end = ey + '-' + em + '-' + ed;

    setprojectStart(date_start.toString());
    setprojectEnd(date_end.toString());
    // document.getElementById("date_field").setAttribute("max", date_end);
    // document.getElementById("date_field").setAttribute("min", date_start);
  };

  const autosave = (data, Index, scopeIndex) => {
    axios
      .post(IP + 'sow/save', {
        data,
        // company_id: company_id,
        headers: {
          'Content-Type': 'appliation/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        if (typeof data.dependencies != 'undefined') {
          let arr = [...weeklyReport];
          arr[scopeIndex].Task[Index].Dependencies.push(...data.dependencies);

          setWeeklyReport(arr);
        }
      })
      .catch((error) => {
        console.log('projectdetails/ProjectOverview.js', ' ', 'line 318', error);
      });
  };

  const changeSowProgress = (value, Index, dataIndex, estimateMeasurement, unit) => {
    const newArr = [...weeklyReport];
    if (estimateMeasurement <= 0) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid input',
        text: `You cannot input your progress because the estimate measurement is ${estimateMeasurement} ${decodeURIComponent(JSON.parse(`"${unit}"`))}. Please update the estimate measurement first.`,
        confirmButtonColor: '#23303D',
        confirmButtonText: 'Update',
        showCancelButton: true,
      }).then((res) => {
        if (res.isConfirmed) {
          history.push(`/projects/Index?id=${projectId}&menu=2&sow_id=${newArr[dataIndex].item[Index].sow_id}&psow_id=${newArr[dataIndex].item[Index].psow_id}`);
        }
      });
    } else {
      newArr[dataIndex].item[Index].status = value;
      newArr[dataIndex].item[Index].accomplishment_edited = false;
      newArr[dataIndex].item[Index].edited = true;
      setWeeklyReport(newArr);
    }
  };

  const changestartdate = (value, Index, dataIndex, category) => {
    console.log(value);
    console.log(Index);
    console.log(dataIndex);
    console.log(category);

    console.log(typeof value);

    // console.log(newArr[dataIndex].item[Index].actual_start_date, 'me here')
    // console.log(value, 'me here')
    // console.log(adjustDateFormat(value), 'qweqwe')

    const newArr = [...weeklyReport];
    if (category === 'target') {
      newArr[dataIndex].item[Index].StartDate = value;
    } else if (category === 'actual') {
      newArr[dataIndex].item[Index].actual_start_date = value;
    }
    newArr[dataIndex].item[Index].edited = true;
    setWeeklyReport(newArr);
  };

  const changeenddate = (value, Index, dataIndex, category) => {
    const newArr = [...weeklyReport];
    if (category === 'target') {
      newArr[dataIndex].item[Index].EndDate = value;
    } else if (category === 'actual') {
      newArr[dataIndex].item[Index].actual_end_date = value;
    }

    newArr[dataIndex].item[Index].edited = true;
    setWeeklyReport(newArr);
  };

  const validateInput = (enteredValue) => {
    // Check if the entered value is a positive number
    // const isValid = enteredValue === '' || /^\d*\.?\d+$/.test(enteredValue);
    const isValid = enteredValue === '' || (/^\d*\.?\d*$/.test(enteredValue) && parseFloat(enteredValue) >= 0);
    // const hasScientificNotation = /[eE]/.test(enteredValue);

    // return isValid && !hasScientificNotation;
    return isValid;
  };

  const changeAccomplishment = (value, estimateMeasurement, unit, Index, dataIndex) => {
    const newArr = [...weeklyReport];
    const valueGreaterThanEstimate = parseFloat(value) > parseFloat(estimateMeasurement);
    if (!estimateMeasurement || valueGreaterThanEstimate) {
      if (estimateMeasurement <= 0) {
        Swal.fire({
          icon: 'error',
          title: 'Invalid input',
          text: `You cannot input your accomplishment because the estimate measurement is ${estimateMeasurement} ${decodeURIComponent(JSON.parse(`"${unit}"`))}. Please update the estimate measurement first.`,
          confirmButtonColor: '#23303D',
          confirmButtonText: 'Update',
          showCancelButton: true,
        }).then((res) => {
          if (res.isConfirmed) {
            history.push(`/projects/Index?id=${projectId}&menu=2&sow_id=${newArr[dataIndex].item[Index].sow_id}&psow_id=${newArr[dataIndex].item[Index].psow_id}`);
          }
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Invalid input',
          text: `Accomplishment cannot be greater than the estimate measurement, ${estimateMeasurement} ${decodeURIComponent(JSON.parse(`"${unit}"`))}`,
          confirmButtonColor: '#23303D',
        });
      }
    }
    const isValid = validateInput(value);
    if (!isValid) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid input',
        text: 'Please enter a positive number',
        confirmButtonColor: '#23303D',
      });
      return;
    } else {
      newArr[dataIndex].item[Index].accomplishment = parseFloat(value);
      newArr[dataIndex].item[Index].accomplishment_edited = true;
      newArr[dataIndex].item[Index].edited = true;
      setWeeklyReport(newArr);
      console.log('newArr accomplishment', newArr);
    }
  };

  const date_autosave = (data, Index, dataIndex) => {
    // NOTE: This function is not needed
    // console.log(data, "unformated");
    // let formattedData = { ...data };
    // if (data.actual_end) {
    //   const endDate = new Date(data.actual_end);
    //   const timezoneOffset = endDate.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
    //   const adjustedDate = new Date(endDate - timezoneOffset);
    //   formattedData.actual_end = adjustedDate.toISOString().split('T')[0];
    // }
    // if (data.actual_start) {
    //   const startDate= new Date(data.actual_start);
    //   const timezoneOffset = startDate.getTimezoneOffset() * 60000; // Convert minutes to milliseconds
    //   const adjustedDate = new Date(startDate - timezoneOffset);
    //   formattedData.actual_start = adjustedDate.toISOString().split('T')[0];
    // }
    // console.log(formattedData, "formated");
    // axios
    //   .post(IP + 'sow/save', {
    //     data: formattedData,
    //     headers: {
    //       'Content-Type': 'appliation/json',
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   })
    //   .then((res) => {
    //     // if (typeof data.dependencies != 'undefined') {
    //     //   let arr = [...taskItem];
    //     //   arr[scopeIndex].Task[Index].Dependencies.push(...data.dependencies);
    //     //   settaskItem(arr);
    //     // }
    //     // if (res.data.status == 1) {
    //     //   if (res.data.response == 1) {
    //     //     requestSowNotifications(company_id, props.id);
    //     //     // refresh screen
    //     //     scopeofwork(props.id, props.type);
    //     //   }
    //     // } else {
    //     //   Swal.fire({
    //     //     icon: 'error',
    //     //     title: res.data.response,
    //     //     text: res.data.description,
    //     //     confirmButtonColor: '#23303D',
    //     //   });
    //     //   scopeofwork(props.id, props.type);
    //     // }
    //   })
    //   .catch((error) => {
    //     console.log('projectdetails/ProjectOverview.js', ' ', 'line 318', error);
    //   });
  };

  const ItemBadge = ({ underBudget, delayed, incomplete }) => {
    let badge = [];

    if (underBudget == 1) {
      badge.push(
        <CBadge color="aimhi-success" className="badge-stats" style={{ marginTop: '15px', width: '150px' }}>
          WITHIN BUDGET
        </CBadge>,
      );
    } else {
      badge.push(
        <CBadge color="aimhi-danger" className="badge-stats" style={{ marginTop: '15px', width: '150px' }}>
          OVER BUDGET
        </CBadge>,
      );
    }

    if (incomplete == 1) {
      badge.push(
        <CBadge color="aimhi-success" className="badge-stats" style={{ marginTop: '15px', width: '150px' }}>
          INCOMPLETE
        </CBadge>,
      );
    } else {
      if (delayed == 0) {
        badge.push(
          <CBadge color="aimhi-success" className="badge-stats" style={{ marginTop: '3px', width: '150px' }}>
            ON-TIME
          </CBadge>,
        );
      } else {
        badge.push(
          <CBadge color="aimhi-danger" className="badge-stats" style={{ marginTop: '3px', width: '150px' }}>
            DELAYED
          </CBadge>,
        );
      }
    }

    return badge;
  };

  const WeeklyCard = isDataLoading ? (
    <Loading />
  ) : weeklyReport?.length > 0 ? (
    weeklyReport.map((data, index) => {
      let dataIndex = index;
      let ontime = true;
      const collapseCard = data.item.map((item, innerIndex) => {
        console.log(item, 'item');
        console.log('data.Total_Cost', data.total_cost);

        // let itemStatus = 50;
        let itemStatus = item.status;
        let colorstatus = '';
        if (itemStatus >= '100') {
          colorstatus = 'green';
        } else if (itemStatus >= '1' && itemStatus <= '99') {
          colorstatus = 'blue';
        } else if (itemStatus <= '0') {
          colorstatus = 'gray';
        }

        // console.log(item, 'item');

        if (item.actual_end_date > item.end_date) {
          ontime = false;
        } else {
          ontime = true;
        }

        return (
          <CCard classNname="shadow report-card" style={{ marginBottom: 0, marginLeft: '40px' }} key={innerIndex}>
            <CCardBody style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 15 }}>
              <CRow>
                <div className="left-design-report float-left"></div>
                {item.new == true ? (
                  <CCol lg="4" style={{ padding: '10px' }}>
                    <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Work Item / Others</span>
                    <CInput value={item.work_item} onChange={(e) => workItemChange(e, dataIndex, innerIndex)} />
                  </CCol>
                ) : (
                  <CCol lg="4" style={{ padding: '10px' }}>
                    <span className="report-label">{item.work_item}</span>
                  </CCol>
                )}

                <CCol lg="1.8" style={{ padding: '0px 10px 10px 10px' }} id="Status">
                  <CCol style={{ textAlign: 'center' }}>
                    <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Status</span>
                  </CCol>
                  <div className="progress-dropdown" style={{ alignContent: 'center' }}>
                    <div className="progress-dropdown" style={{ marginTop: '3px' }}>
                      <div style={{ position: 'relative' }}>
                        <CProgress style={{ height: '15px', width: '8rem' }}>
                          <div
                            id="editable"
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              color: 'black',
                              fontWeight: 'bold',
                            }}
                            onDoubleClick={(e) => {
                              e.target.innerText = e.target.innerText.replace('%', '');
                              e.target.contentEditable = true;
                              e.target.focus();
                              e.target.style.paddingLeft = '1rem';
                              e.target.style.paddingRight = '1rem';
                            }}
                            onKeyDown={(e) => {
                              if (e.key == 'Enter') {
                                e.target.blur();
                              }
                            }}
                            onBlur={(e) => {
                              const newValue = parseInt(e.target.innerText);
                              if (newValue > 100 || newValue < 0) {
                                e.target.innerText = `${itemStatus}%`;
                              } else {
                                changeSowProgress(newValue, innerIndex, dataIndex, item.estimate_measurement, item.unit);
                                itemStatus += newValue;
                                e.target.innerText = `${newValue}%`;
                              }
                              e.target.contentEditable = false;
                              e.target.style.paddingLeft = '8px';
                              e.target.style.paddingRight = '8px';
                            }}
                            // onBlur={(e) => {
                            //   const newValue = e.target.innerText.replace('%', '');
                            //   if (newValue > 100 || newValue < 0) {
                            //     e.target.innerText = itemStatus;
                            //   } else {
                            //     const newArr = [...taskItem];
                            //     newArr[scopeIndex].Task[innerIndex].Status = newValue;
                            //     settaskItem(newArr);
                            //     autosave(
                            //       {
                            //         id: itemtask.id,
                            //         status: newValue,
                            //       },
                            //       innerIndex,
                            //       scopeIndex,
                            //     );
                            //   }
                            //   e.target.contentEditable = false;
                            //   e.target.style.paddingLeft = '8px';
                            //   e.target.style.paddingRight = '8px';
                            // }}
                            // onKeyDown={(e) => {
                            //   if (e.key === 'Enter') {
                            //     const newValue = e.target.innerText.replace('%', '');
                            //     if (newValue > 100 || newValue < 0) {
                            //       e.target.innerText = itemStatus;
                            //     } else {
                            //       const newArr = [...taskItem];
                            //       newArr[scopeIndex].Task[innerIndex].Status = newValue;
                            //       settaskItem(newArr);
                            //       autosave(
                            //         {
                            //           id: itemtask.id,
                            //           status: newValue,
                            //         },
                            //         innerIndex,
                            //         scopeIndex,
                            //       );
                            //     }
                            //     e.target.contentEditable = false;
                            //     e.target.style.paddingLeft = '8px';
                            //     e.target.style.paddingRight = '8px';
                            //   }
                            // }}
                          >
                            <span>{`${itemStatus}%`}</span>
                          </div>
                          <CProgressBar value={itemStatus} className={colorstatus + ' ' + 'select-design'} />
                        </CProgress>
                      </div>
                    </div>
                  </div>
                  <CCol xs="12" sm="12" md="12" lg="12" style={{ textAlign: 'center', marginTop: '12px' }}>
                    <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Accomplishment</span>
                  </CCol>
                  <div style={{ display: 'flex', flexDirection: 'row', width: '130px' }}>
                    <CCol lg="8" md="8" sm="8" style={{ textAlign: 'center', padding: '0px 5px 0px 0px' }}>
                      <CInput
                        style={{ width: '100%', height: '25px', padding: '5px', fontSize: '12px' }}
                        value={item.accomplishment}
                        type="number"
                        onChange={(e) => {
                          changeAccomplishment(e.target.value, item.estimate_measurement, item.unit, innerIndex, dataIndex);
                        }}
                        onWheel={(e) => e.target.blur()}
                      ></CInput>
                    </CCol>
                    <CCol lg="4" md="4" sm="4" style={{ textAlign: 'center', padding: 0 }}>
                      {/* <CSelect style={{ width: '100%', height: '25px', fontSize: '10px', padding: 0 }} value={selectedUnit} onChange={(e) => setSelectedUnit(parseInt(e.target.value))} disabled>
                          <option value={1}>sqm</option>
                          <option value={2}>sqft</option>
                          <option value={3}>m</option>
                          <option value={4}>km</option>
                        </CSelect> */}
                      <CInput style={{ width: '100%', height: '25px', padding: '5px', fontSize: '12px', textAlign: 'center' }} value={decodeURIComponent(JSON.parse(`"${item.unit}"`))} disabled></CInput>
                    </CCol>
                  </div>
                </CCol>

                <CCol lg="4" className="text-center">
                  <CRow>
                    <CCol className="text-center" id="Target/Actual Start">
                      <CRow style={{ paddingBottom: '10px' }}>
                        <CCol xs="12" sm="12" md="12" lg="12">
                          <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Target Start</span>
                        </CCol>
                        <CCol xs="12" sm="12" md="12" lg="12">
                          {/* <DatePicker
                          format="MM/dd/yyyy"
                          monthPlaceholder="mm"
                          dayPlaceholder="dd"
                          yearPlaceholder="yyyy"
                          onChange={(value) => {
                            changestartdate(value, innerIndex, dataIndex, 'target');
                          }}
                          value={item.start_date}
                          disabled
                        /> */}
                          <DatePicker
                            className="reports"
                            placeholderText="mm/dd/yyyy"
                            value={item.start_date}
                            disabled
                            onChange={(value) => {
                              changestartdate(value, innerIndex, dataIndex, 'target');
                            }}
                          />
                        </CCol>

                        <CCol xs="12" sm="12" md="12" lg="12">
                          <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Actual Start</span>
                        </CCol>
                        <CCol xs="12" sm="12" md="12" lg="12">
                          {/* <DatePicker
                          format="MM/dd/yyyy"
                          monthPlaceholder="mm"
                          dayPlaceholder="dd"
                          yearPlaceholder="yyyy"
                          onBlur={(value) => {
                            date_autosave(
                              {
                                id: item.id,
                                actual_start: item.target_date,
                              },
                              innerIndex,
                              dataIndex
                            );
                          }}
                          onChange={(value) => {
                            changestartdate(value, innerIndex, dataIndex, 'actual');
                          }}
                          value={item.actual_start_date}
                        /> */}
                          <DatePicker
                            className="reports"
                            placeholderText="mm/dd/yyyy"
                            selected={item.actual_start_date ? new Date(item.actual_start_date) : null}
                            value={item.actual_start_date}
                            isClearable
                            onBlur={(value) => {
                              date_autosave(
                                {
                                  id: item.id,
                                  actual_start: item.target_date,
                                },
                                innerIndex,
                                dataIndex,
                              );
                            }}
                            onChange={(value) => {
                              changestartdate(adjustDateFormat(value ? value.toLocaleDateString() : null), innerIndex, dataIndex, 'actual');
                            }}
                          />
                        </CCol>
                      </CRow>
                    </CCol>
                    <CCol className="text-center" id="Target/Actual End">
                      <CRow style={{ paddingBottom: '10px' }}>
                        <CCol xs="12" sm="12" md="12" lg="12">
                          {' '}
                          <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Target End</span>
                        </CCol>
                        <CCol xs="12" sm="12" md="12" lg="12">
                          {/* <DatePicker
                          format="MM/dd/yyyy"
                          monthPlaceholder="mm"
                          dayPlaceholder="dd"
                          yearPlaceholder="yyyy"
                          onChange={(value) => {
                            changestartdate(value, innerIndex, dataIndex, 'target');
                          }}
                          value={item.end_date}
                          disabled
                        /> */}
                          <DatePicker
                            className="reports"
                            placeholderText="mm/dd/yyyy"
                            isClearable
                            value={item.end_date}
                            disabled
                            onChange={(value) => {
                              changestartdate(value, innerIndex, dataIndex, 'target');
                            }}
                          />
                          {/* {item.target_date ? item.target_date : 'mm/dd/yyyy'} */}
                        </CCol>
                        <CCol xs="12" sm="12" md="12" lg="12">
                          {' '}
                          <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Actual End</span>
                        </CCol>
                        <CCol xs="12" sm="12" md="12" lg="12">
                          {/* <input
                          type="date"
                          id="date_field"
                          className="date-picker-design unstyled "
                          min={projectStart}
                          max={projectEnd}
                          onBlur={(e) => {
                            autosave(
                              {
                                id: item.id,
                                start_date: e.target.value,
                              },
                              // index,
                              innerIndex,
                              dataIndex,
                            );
                          }}
                          onChange={(e) => {
                            changestartdate(e, innerIndex, dataIndex, 'target');
                          }}
                          value={item.target_date ? item.target_date : 'mm/dd/yyyy'}
                          disabled={permission['update'][0] != 1 || permission['create'][0] != 1}
                        ></input> */}
                          <DatePicker
                            className="reports"
                            placeholderText="mm/dd/yyyy"
                            selected={item.actual_end_date ? new Date(item.actual_end_date) : null}
                            value={item.actual_end_date}
                            isClearable
                            onBlur={(value) => {
                              date_autosave(
                                {
                                  id: item.id,
                                  actual_end: item.target_date,
                                },
                                innerIndex,
                                dataIndex,
                              );
                            }}
                            onChange={(value) => {
                              changeenddate(adjustDateFormat(value ? value.toLocaleDateString() : null), innerIndex, dataIndex, 'actual');
                            }}
                          />
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                </CCol>

                <CCol lg="2" style={{ padding: '0px 10px 10px 10px' }} id="Comment/s">
                  <CCol>
                    <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Comment/s</span>
                  </CCol>
                  <CRow>
                    <CCol>
                      <CTextarea value={item.comments} onChange={(e) => commentChange(e, dataIndex, innerIndex)}></CTextarea>
                    </CCol>
                    {item.new == true && <FaTrashAlt style={{ height: '20px', width: '20px', color: 'red' }} onClick={() => remove(dataIndex, innerIndex)} />}
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        );
      });
      return (
        <CCol key={index} style={{ marginTop: '20px' }}>
          <CCard style={{ marginBottom: 0 }} className="shadow" onClick={() => toggleShow(data.phase_id)}>
            <CCardBody>
              <CRow>
                <CCol lg="4">
                  {/* <CCol lg="4" onClick={() => toggleShow(data.phase_id)}> */}
                  <span className="circle-number float-left">
                    <span>{index + 1}</span>
                  </span>
                  <div className="report-sow-label">{data.phase}</div>
                </CCol>

                <CCol lg="2" className="align-center" style={{ marginLeft: '30px' }}>
                  <div className="circular-progress-size">
                    <CircularProgressbarWithChildren
                      className="circular-progress-size"
                      value={data.progress.toFixed(2)}
                      text={`${data.progress.toFixed(2)}%`}
                      styles={{
                        path: {
                          stroke: '#351D75',
                          strokeLinecap: 'butt',
                          transition: 'stroke-dashoffset 0.5s ease 0s',
                          transformOrigin: 'center center',
                          circleRatio: '0',
                          strokeWidth: '-10',
                        },
                        trail: {
                          stroke: '#9c9c9c',
                          strokeLinecap: 'butt',
                          transform: 'rotate(0.25turn)',
                          transformOrigin: 'center center',
                          circleRatio: '0',
                          strokeWidth: '-10',
                        },
                        text: {
                          fill: '#23303D',
                          fontSize: '20px',
                          fontWeight: '700',
                        },
                        background: {
                          fill: '#54c2a2',
                        },
                      }}
                    />
                  </div>
                  <span className="status-label align-text-mobile">Progress</span>
                </CCol>

                <CCol lg="4" xl="2">
                  <ItemBadge underBudget={data.under_budget} delayed={data.delayed} incomplete={data.incomplete} />
                </CCol>

                {/* <CCol xl="5">
                <CCol style={{ marginTop: '20px' }}>
                  <CProgress size="md">
                    <CProgressBar
                      color="aimhi-textlight-neutral"
                      value={data.total_budget == 0 ? 0 : data.total_cost}
                      max={data.total_budget == 0 ? 1 : data.total_budget}
                      // value={data.total_cost}
                      // max={data.total_budget || 1}
                      showPercentage
                    />
                  </CProgress>
                </CCol>
              </CCol> */}

                {/* <CCol xl="2">
                <CButton
                  size="sm"
                  color="aimhi-primary"
                  onClick={() => AddNew(Index)}
                >
                  Add New
                </CButton>
              </CCol> */}
              </CRow>
            </CCardBody>
          </CCard>
          <CCol xl="12" style={{ padding: 0 }}>
            <CCollapse show={toggle.includes(data.phase_id)}>{collapseCard}</CCollapse>
          </CCol>
        </CCol>
      );
    })
  ) : (
    <span>No Data</span>
  );

  const handleDownload = async (project_id, type) => {
    const [start_date, end_date] = date.split(' - ');
    setIsLoading(true);
    await DownloadReportWeekly(project_id, start_date.trim(), end_date.trim(), type);
    setIsLoading(false);
  };

  return (
    <>
      <CRow>
        <CCol xs="12" sm="12" md="12" lg="12" xl="12" style={{ marginTop: '20px' }}>
          <CRow xl="12">
            <CCol xl="6" style={{ marginLeft: '20px', marginRight: '190px' }}>
              <span style={{ fontWeight: 'bold', fontSize: '16px' }}>{date}</span>
            </CCol>
            {/* <CCol xl="2"></CCol> */}
            <CCol xl="2">
              {isDownloadableReportAllowed && (
                <CButton
                  color="aimhi-textlight-neutral"
                  variant="outline"
                  size="sm"
                  block
                  onClick={() => {
                    handleDownload(props.id, 1);
                  }}
                >
                  {isLoading ? (
                    <>
                      <CSpinner color="#23303D" size="sm" />
                      <span style={{ marginLeft: '8px' }}>Download</span>
                    </>
                  ) : (
                    'Download'
                  )}
                </CButton>
              )}
            </CCol>
            <CCol xl="2">
              <CButton color="aimhi-textlight-neutral" size="sm" block onClick={saveChanges}>
                Save changes
              </CButton>
            </CCol>
          </CRow>

          {WeeklyCard}
        </CCol>
      </CRow>
    </>
  );
};

export default Weekly;
