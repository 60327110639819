import { Alert } from "@coreui/coreui";
import $axios from 'src/utils/axios';
import IP from "src/StoreGlobal/IP";

let companyId = parseInt(localStorage.getItem("company_id"));
let accessToken = localStorage.getItem("token");
let user_id = parseInt(localStorage.getItem("user_id"));

export const GetPosition = (projectId) => {
  let params = {
    companyId: parseInt(localStorage.getItem("company_id")),
    accessToken: localStorage.getItem("token")
  }
  let data = {
    project_id: projectId,
    company_id: params.companyId,
  };

  return $axios.post("manpower/list/all", {
    ...data
  })
};

export const AddPosition = (val) => {
  let companyId = parseInt(localStorage.getItem("company_id"));
  let accessToken = localStorage.getItem("token");

  let data = {
    position: val.position,
    description: val.description,
    // force: val.force,
    status: val.status,
  };

  return $axios.post('manpower/add/position', {
    ...data
  })
};

export const GetManpowerPosition = (detailed) => {
  let companyId = parseInt(localStorage.getItem("company_id"))
  console.log(detailed, "detailed here");
  let routes = detailed
    ? `manpower/position?detailed=${detailed}`
    : `manpower/position`;

  return $axios.get(routes)
};

export const EditManpowerPosition = (val, position_id) => {
  let data = {
    data: val,
  };

  return $axios.put(`manpower/edit/position/${position_id}`, {
    ...data
  })
};

export const ArchivePosition = (method, position_id) => {
  return $axios[method.toLowerCase()](`manpower/archive/position/${position_id}`)
};

export const GetManpowerPositionById = (position_id) => {
  return $axios.get(`manpower/position?id=${position_id}`)
};

export const TransferManpower = (val) => {
  return $axios.put(`manpower/transfer`, {
    ...val
  })
};
