import $axios from 'src/utils/axios';
export const GetProjectTabStatus = (projectId) => {
  return $axios.get(`project/tab-status/${projectId}`);
};

export const ProjectList = (filter = {}, name_only = false, sort = {}) => {
  // NOTE: Need to refetch values from local storage again
  let values = {
    companyId: parseInt(localStorage.getItem('company_id')) || null,
    token: localStorage.getItem('token') || null,
    userId: parseInt(localStorage.getItem('user_id')) || null,
  };

  let data = {
    company_id: values.companyId,
    name_only: name_only,
    filter: filter,
    sort: sort,
    user_id: values.userId, // this will be not necessary if token auth is implemented properly
  };

  return $axios.post('project/list', {
    ...data,
  });
};

export const GetProjectType = () => {
  return $axios.get(`connect/project/types`);
};

export const GetProjectCategory = () => {
  return $axios.get(`connect/project/category`);
};

export const SaveProject = (val) => {
  let data = {
    data: val,
  };
  return $axios.post('project/save', {
    ...data,
  });
};

export const ArchivesRestore = (id, method) => {
  const company_id = parseInt(localStorage.getItem('company_id'));
  return $axios[method.toLowerCase()](`project/archive/${company_id}/${id}`);
};

export const SaveProjectAccess = (members, project_id) => {
  let data = {
    project_id: project_id,
    members: members,
  };
  return $axios.post('project/access', {
    ...data,
  });
};

export const GetllMembersProject = (project_id) => {
  return $axios.get(`project/access/${project_id}`);
};

export const DuplicateProject = (project_id) => {
  return $axios.post('project/duplicate', {
    project_id,
  });
};

export const DownloadReport = async (project_id, type) => {
  const Swal = require('sweetalert2');

  return $axios
    .post(`project/download/reports`, { project_id: project_id, report_type: type, type: 'xlxs' }, { responseType: 'blob' })
    .then((response) => {
      const url_2 = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url_2;
      if (type === 1) link.setAttribute('download', `Estimate_Report.xlsx`);
      else if (type === 2) link.setAttribute('download', `BOM_Report.xlsx`);
      else if (type === 3) link.setAttribute('download', `BOQ_Report.xlsx`);
      else if (type === 4) link.setAttribute('download', `SWA_Report.xlsx`);
      else if (type === 5) link.setAttribute('download', `Schedule.xlsx`);
      else if (type === 6) link.setAttribute('download', `Material_Utilization_Report.xlsx`);
      else if (type === 7) link.setAttribute('download', `Two_Weeks_Ahead_Report.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error) => {
      const reader = new FileReader();
      // console.error('Error downloading report:', error);
      reader.onload = () => {
        const errorMessage = JSON.parse(reader.result).error?.[0]?.error || JSON.parse(reader.result).error;
        console.log('errorMessage', errorMessage);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: errorMessage,
          confirmButtonColor: '#23303D',
        });
      };
      reader.onerror = () => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Something went wrong...',
          confirmButtonColor: '#23303D',
        });
      };
      reader.readAsText(error.response.data);

      // console.log('errorMessage', errorMessage);
      // Swal.fire({
      //   icon: 'error',
      //   title: 'Error',
      //   text: 'Something went wrong...',
      //   confirmButtonColor: '#23303D',
      // });
    });
};

export const DownloadReportWeekly = async (project_id, start_date, end_date, type) => {
  const Swal = require('sweetalert2');

  return $axios
    .post(`project/download/reports/weekly`, { project_id: project_id, start_date: start_date, end_date: end_date, report_type: type, type: 'xlxs' }, { responseType: 'blob' })
    .then((response) => {
      const url_2 = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url_2;
      if (type === 1) link.setAttribute('download', `Current_Week_Report.xlsx`);
      else if (type === 4) link.setAttribute('download', `SWA_Report.xlsx`);
      else link.setAttribute('download', `Two_Weeks_Ahead_Report.xlsx`);

      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error) => {
      console.error('Error downloading report:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Something went wrong...',
        confirmButtonColor: '#23303D',
      });
    });
};

export const GetTabWarnings = async (project_id) => {
  return $axios.get(`project/tab-warnings/${project_id}`);
};
