import React, { useState, useEffect } from 'react';
import {
  CButton,
  CCol,
  CRow,
  CSelect,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CDropdownDivider,
} from '@coreui/react';

import 'react-circular-progressbar/dist/styles.css';
import './ProjectDetails.css';
import ThingsToConsider from '../aimhi-widgets/ThingToConsider';
import axios from 'axios';
import $axios from 'src/utils/axios';
import IP from '../../StoreGlobal/IP';
import { useSelector } from 'react-redux';
import { FaRecycle } from 'react-icons/fa';
import { ProjectList } from 'src/request/Projects';
import { TransferManpower } from 'src/request/Manpower';

const team = [
  {
    position: 'Site Engineer',
    name: [''],
    mobile: [''],
    location: [''],
    Tranfer: '',
    dropdown: [
      {
        value: '2',
        label: 'jore Dawal',
        location: 'Tagum City',
        mobile: '1235678900',
      },
      {
        value: '3',
        label: 'wilson Cabanlit',
        location: 'Panabo City',
        mobile: '1235678900',
      },
      {
        value: '1',
        label: 'Ash Villos',
        location: 'Panabo City',
        mobile: '1235678900',
      },
    ],
  },
  {
    position: 'ForeMan',
    name: [''],
    mobile: [''],
    location: [''],
    Tranfer: '',
    dropdown: [
      {
        value: '3',
        label: 'jore Dawal',
        location: 'Tagum City',
        mobile: '1235678900',
      },
      {
        value: '2',
        label: 'wilson Cabanlit',
        location: 'Panabo City',
        mobile: '4564871834548',
      },
      {
        value: '1',
        label: 'Ash Villos',
        location: 'Panabo City',
        mobile: '4564871834548',
      },
    ],
  },
  {
    position: 'Skilled',
    name: [''],
    mobile: [''],
    location: [''],
    Tranfer: '',
    dropdown: [
      {
        value: '3',
        label: 'jore Dawal',
        location: 'Tagum City',
        mobile: '1235678900',
      },
      {
        value: '2',
        label: 'wilson Cabanlit',
        location: 'Panabo City',
        mobile: '4564871834548',
      },
      {
        value: '1',
        label: 'Ash Villos',
        location: 'Panabo City',
        mobile: '4564871834548',
      },
    ],
  },
];

const Team = (props) => {
  const Swal = require('sweetalert2');
  let accessToken = localStorage.getItem('token');
  let user_id = parseInt(localStorage.getItem('user_id'));
  const [modal, setModal] = useState(false);
  const [listTeam, setlistTeam] = useState([]);
  const [selected, setSelected] = useState([]);
  const [oldteam, setoldteam] = useState([]);
  const [oldId, setoldId] = useState('');
  const [projectList, setProjectList] = useState([]);
  let permission = useSelector((state) => state.permission);
  useEffect(() => {
    getteam(props.id);
    getProjectList();
  }, []);

  const getProjectList = () => {
    ProjectList({}, true).then((res) => {
      setProjectList(res.data.response);
    });
  };

  const multiselectOnchange = (e, Index) => {
    let val = e.filter((value, index) => e.indexOf(value) === index);

    let arr = [...listTeam];

    arr[Index].value = [];

    arr[Index].value.push(...val);

    setlistTeam(arr);
  };

  const getteam = (project_id) => {
    let company_id = -1;

    if (localStorage.getItem('company_id') !== null) {
      company_id = localStorage.getItem('company_id');
    }

    $axios
      .post(IP + 'manpower/list/all', {
        company_id,
        project_id,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        // console.log('res.team', res.data.response);
        // setlistTeam(res.data.response);
        // setoldteam(res.data.response);

        // if listTeam is empty directly use the response data
        const updatedList = listTeam.length === 0 ? res.data.response : [...listTeam];

        // restore the original order
        updatedList.forEach((item, index) => {
          item.name = listTeam[index] ? listTeam[index].name : res.data.response[index].name;
        });

        setlistTeam(updatedList);
        setoldteam(updatedList);
      })
      .catch((error) => {
        // console.log('projectdetails/Team.js', ' ', 'line 196', error);
        Swal.fire({
          icon: 'error',
          title: error.response.data.message,
          text: error.response.data.response,
          confirmButtonColor: '#23303D',
        });
      });
  };

  const changename_autosave = (e, Index, D_Index, pos_id) => {
    if (e.target.value === '') {
      let arr1 = [...listTeam];
      let name1 = [...arr1[Index].name];
      name1[D_Index] = e.target.value;
      let loc1 = arr1[Index].location;
      let mobile1 = [...arr1[Index].mobile];
      mobile1[D_Index] = e.target.value;
      // setlistTeam(arr1);

      let data = {};
      data = {
        project_id: props.id,
        name: arr1[Index].name[D_Index],
        location: loc1[D_Index],
        mobile: mobile1[D_Index],
        pos_id: pos_id,
        replace: {
          name: arr1[Index].name[D_Index],
          pos_id: pos_id,
        },
      };

      axios
        .post(IP + 'manpower/save', {
          data,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then(async (res) => {
          // use the stored order when updating the state
          let updatedList = [...listTeam];
          updatedList[Index].name = name1;
          updatedList[Index].mobile = mobile1;
          const filteredList = updatedList.findIndex((e) => e.pos_id === pos_id)
          updatedList[filteredList].name[D_Index] = ''
          setlistTeam(updatedList);
          // // refresh UI
          // await getteam(props.id);
        })
        .catch((error) => {
          console.log('projectdetails/Team.js', ' ', 'line 258', error);
        });
    } else {
      let arr = [...listTeam];
      let SelectVal = arr[Index].dropdown.filter((a) => a.value == e.target.value);
      let nameToReplace = arr[Index].name[D_Index];
      arr[Index].name[D_Index] = e.target.value;
      arr[Index].location[D_Index] = SelectVal[0].location == SelectVal[0].location;
      arr[Index].mobile[D_Index] = SelectVal[0].mobile;

      // setlistTeam(arr)
      let data = {};
      if (nameToReplace) {
        data = {
          project_id: props.id,
          name: arr[Index].name[D_Index],
          location: arr[Index].location[D_Index],
          mobile: arr[Index].mobile[D_Index],
          pos_id: pos_id,
          replace: {
            name: nameToReplace,
            pos_id: pos_id,
          },
        };
      } else {
        data = {
          project_id: props.id,
          name: arr[Index].name[D_Index],
          location: arr[Index].location[D_Index],
          mobile: arr[Index].mobile[D_Index],
          pos_id: pos_id,
        };
      }
      axios
        .post(IP + 'manpower/save', {
          data,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((res) => {
          console.log(res.data.response)
          let updatedList = [...listTeam];
          const filteredList = updatedList.findIndex((e) => e.pos_id === pos_id)
          updatedList[filteredList].name[D_Index] = parseInt(arr[Index].name[D_Index])
          // refresh UI

          console.log(updatedList[filteredList].name[D_Index], filteredList, 'janrey', updatedList)
          setlistTeam(updatedList);
          // getteam(props.id);
        })
        .catch((error) => {
          console.log('projectdetails/Team.js', ' ', 'line 258', error);
        });
    }
  };

  const addmore = (Index) => {
    let arr = [...listTeam];
    arr[Index].name.push('');
    arr[Index].location.push('');
    arr[Index].mobile.push('');

    setlistTeam(arr);
  };

  const hello = () => {
    return <h1>hellasdasdasdo</h1>;
  };

  const transferLabor = (p_id, L_Index, T_Index) => {
    let arr_team = [...listTeam];
    console.log(arr_team[L_Index].name[T_Index]);

    let data = {
      manpower_id: arr_team[L_Index].name[T_Index],
      project_id: p_id,
    };

    if (arr_team[L_Index].name[T_Index] == '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: "You can't transfer because manpower is empty",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      TransferManpower(data).then((res) => {
        console.log(res, 'here')
        Swal.fire({
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500,
        });
        
        // refresh list
        getteam(props.id)
      }).catch((error) => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: error.response.data.response,
          showConfirmButton: false,
          timer: 1500,
        });
      });
    }


  };

  const tbody = listTeam.map((item, Index) => {
    let L_Index = Index;
    const transfer = item.name.map((tranfer, T_Index) => {
      return (
        <CDropdown size="sm">
          <CDropdownToggle className="caret">
            <FaRecycle style={{ width: '25px', height: '25px' }} />
          </CDropdownToggle>
          <CDropdownMenu style={{ height: '300px', overflow: 'scroll' }}>
            <CDropdownItem header>Action</CDropdownItem>
            <CDropdownDivider />
            {projectList.map((data, Index) => {
              return (
                <CDropdownItem
                  style={{
                    width: '250px',
                    whiteSpace: 'normal',
                  }}
                  onClick={() => transferLabor(data.id, L_Index, T_Index)}
                >
                  <span>{data.name}</span>
                </CDropdownItem>
              );
            })}
          </CDropdownMenu>
        </CDropdown>
      );
    });
    const option = item.dropdown.map((list, Index) => {
      // item.name.includes(e.value)
      console.log(listTeam.find((e) => e.pos_id === item.pos_id), item)
      return (
        <option value={list.value} disabled={item.name.includes(list.value)} key={Index}>
          {list.label}
        </option>
      );
    });
    const select = item.name.map((name, S_Index) => {
      return (
        <CSelect
          key={S_Index}
          style={{ marginTop: '10px' }}
          size="sm"
          value={listTeam[Index].name[S_Index]}
          onClick={() => {
            setoldId(name);
          }}
          onChange={(e) => {
            changename_autosave(e, Index, S_Index, item.pos_id, setoldId(name));
          }}
          disabled={permission['update'][0] != 1}
          // onBlur={() => autosave(Index, S_Index, item.pos_id)}
        >
          <option value="">Select</option>
          {option}
        </CSelect>
      );
    });
    const location = item.location.map((location, L_Index) => {
      return (
        <div key={L_Index} className={L_Index == 0 ? 'margin_top_ss' : 'margin_table_top'}>
          <span>{location}</span>
        </div>
      );
    });

    const mobile = item.mobile.map((mobile, M_Index) => {
      console.log('mobile', mobile);

      return (
        <div key={M_Index} className={M_Index == 0 ? 'margin_top_ss' : 'margin_table_top'}>
          <span>{mobile !== '' ? mobile : '\u00A0'}</span>
        </div>
      );
    });
    return (
      <tr key={Index}>
        <td>{item.position}</td>
        <td>
          {select}

          <CButton style={{ fontColor: 'blue' }} onClick={() => addmore(Index)} disabled={permission['update'][0] != 1}>
            <u>Add more</u>
          </CButton>
        </td>
        {/* <td>{location}</td> */}
        <td>{mobile}</td>
        <th>{transfer}</th>
      </tr>
    );
  });

  return (
    <>
      <CRow>
        <CCol xs="12" sm="12" md="12" lg="12" xl="9" style={{ marginTop: '20px' }}>
          <h2>Employee List</h2>
          <table className="table ">
            <thead style={{ borderBottom: '10px' }}>
              <tr>
                <th>Position</th>
                <th>Name</th>
                {/* <th>Location</th> */}
                <th>Mobile</th>
                <th>Transfer</th>
              </tr>
            </thead>
            <tbody>{tbody}</tbody>
          </table>
        </CCol>

        <CCol xs="12" sm="12" md="12" lg="12" xl="3">
          <ThingsToConsider />
        </CCol>
      </CRow>
    </>
  );
};

export default Team;
