import React, { useState, useEffect, useRef } from 'react';
import { CButton, CCol, CRow, CInput, CTabs, CNav, CNavItem, CNavLink, CLabel, CSelect, CFormGroup, CModal, CModalHeader, CModalTitle, CModalFooter, CModalBody, CInputCheckbox, CTextarea, CCard, CCardHeader, CCollapse, CCardBody } from '@coreui/react';

import 'react-circular-progressbar/dist/styles.css';
import './ProjectDetails.css';
import { useReactToPrint } from 'react-to-print';
import ThingsToConsider from '../aimhi-widgets/ThingToConsider';
import PrintEquipmentRequest from './PrintEquipmentRequest';
import $axios from 'src/utils/axios';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import IP from '../../StoreGlobal/IP';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import Index from '../projects/Index';
import CreatableSelect from 'react-select/creatable';
import { FaPencilAlt, FaTrash, FaTrashAlt, FaAngleDown, FaAngleRight } from 'react-icons/fa';
import { EquipmentRequestHistoryEdit, GetTotalFuelOrder } from 'src/request/Equipment';

let equipment_res = {
  items: [
    {
      tx_id: 35,
      equipment_category: 'Eq Test Rental',
      model: 'Brand Test Rental',
      date_start: '2023-03-22',
      date_end: '2023-03-23',
      notes: '',
      rental: 1,
    },
  ],
  pr_num: '03132312543740',
  approver: 'Wilson',
  approver_position: '',
  preparer: 'Wilson',
  preparer_position: 'Programmer',
  date_approved: '03/13/2023',
  date_requested: '03/13/2023',
  notes: null,
};

let fuel_res = {
  items: [
    {
      tx_id: 21,
      category: 'Diesel',
      quantity: 2.0,
      unit_price: 50.0,
      total_price: 100.0,
      supplier: 'Shell',
      date_deployment: '2023-03-08',
      notes: '',
    },
  ],
  pr_num: '03072303354840',
  approver: 'Ash',
  approver_position: 'Super Admin',
  preparer: 'Wilson',
  preparer_position: 'Programmer',
  date_approved: '03/07/2023',
  date_requested: '03/07/2023',
  notes: 'tst',
};
let er_data = [
  {
    er_number: 1001,
    request_date: '6/10/222 10:22:44',
    preparer: 'Jore Dawal',
  },
];

const equipment = [
  {
    equipment: 'Backhoe',
    description: '',
    rental: 0,
    location: '',
    date_start: '',
    date_end: '',
    request: 0,
    equipment_details: [
      {
        label: 'honda',
        value: '1',
        location: 'tagum',
      },
    ],
  },
  {
    equipment: 'Payloader',
    description: '',
    rental: 0,
    location: '',
    date_start: '',
    date_end: '',
    request: 0,
    equipment_details: [
      {
        label: 'yamaha',
        value: 'asdasdasd',
        location: 'gensan',
      },
    ],
  },
  {
    equipment: 'Dump Truck',
    description: '',
    rental: 0,
    location: '',
    date_start: '',
    date_end: '',
    request: 0,
    equipment_details: [
      {
        label: 'Suzuki',
        value: '1',
        location: 'Tagum',
      },
    ],
  },
];

let fuel_data = [
  {
    category: 'Diesel',
    total_ordered: 0,
    liters: null,
    unit_price: null,
    total_price: null,
    supplier: null,
    date_deployment: null,
    notes: null,
  },
  {
    category: 'Gasoline',
    total_ordered: 0,
    liters: null,
    unit_price: null,
    total_price: null,
    supplier: null,
    date_deployment: null,
    notes: null,
  },
  {
    category: 'Kerosene',
    total_ordered: 0,
    liters: null,
    unit_price: null,
    total_price: null,
    supplier: null,
    date_deployment: null,
    notes: null,
  },
  {
    category: 'Oil',
    total_ordered: 0,
    liters: null,
    unit_price: null,
    total_price: null,
    supplier: null,
    date_deployment: null,
    notes: null,
  },
  {
    category: 'Lubricant',
    total_ordered: 0,
    liters: null,
    unit_price: null,
    total_price: null,
    supplier: null,
    date_deployment: null,
    notes: null,
  },
];

// const unavailable_date = [
//   {
//     date_start: "02/20/2022",
//     date_end: "02/25/2022",
//   },
//   {
//     date_start: "02/06/2022",
//     date_end: "02/11/2022",
//   },
// ];

const equipmentData = [
  {
    equipmentName: 'Equipment 1',
    details: [
      {
        sow: 'Scope 1',
        work_item: 'Work Item 1',
        sow_id: 1,
        work_item_id: 2,
        category_id: 23,
        counter: 0,
        date_end: '',
        date_start: '',
        description: '',
        equipment_details: [
          {
            equipment_id: 508,
            label: '',
            value: 48,
          },
          {
            equipment_id: 509,
            label: 'XS',
            value: 687,
          },
        ],
        location: '',
        rental: 0,
        request: 0,
        unavailable_date: [],
        request_count: 0,
      },
      {
        sow: 'Scope 2',
        work_item: 'Work Item 2',
        sow_id: 2,
        work_item_id: 3,
        category_id: 24,
        counter: 1,
        date_end: '',
        date_start: '',
        description: '',
        equipment_details: [
          {
            equipment_id: 508,
            label: '',
            value: 48,
          },
          {
            equipment_id: 509,
            label: 'XS',
            value: 687,
          },
        ],
        location: '',
        rental: 0,
        request: 0,
        unavailable_date: [],
        request_count: 0,
      },
    ],
  },
  {
    equipmentName: 'Equipment 2',
    details: [
      {
        sow: 'Scope 3',
        work_item: 'Work Item 3',
        sow_id: 3,
        work_item_id: 4,
        category_id: 25,
        counter: 2,
        date_end: '',
        date_start: '',
        description: '',
        equipment_details: [
          {
            equipment_id: 508,
            label: '',
            value: 48,
          },
          {
            equipment_id: 509,
            label: 'XS',
            value: 687,
          },
        ],
        location: '',
        rental: 0,
        request: 0,
        unavailable_date: [],
        request_count: 0,
      },
    ],
  },
  {
    equipmentName: 'Equipment 3',
    details: [
      {
        sow: 'Scope 3',
        work_item: 'Work Item 3',
        sow_id: 3,
        work_item_id: 4,
        category_id: 25,
        counter: 2,
        date_end: '',
        date_start: '',
        description: '',
        equipment_details: [
          {
            equipment_id: 508,
            label: '',
            value: 48,
          },
          {
            equipment_id: 509,
            label: 'XS',
            value: 687,
          },
        ],
        location: '',
        rental: 0,
        request: 0,
        unavailable_date: [],
        request_count: 0,
      },
    ],
  },
  {
    equipmentName: 'Equipment 4',
    details: [
      {
        sow: 'Scope 3',
        work_item: 'Work Item 3',
        sow_id: 3,
        work_item_id: 4,
        category_id: 25,
        counter: 2,
        date_end: '',
        date_start: '',
        description: '',
        equipment_details: [
          {
            equipment_id: 508,
            label: '',
            value: 48,
          },
          {
            equipment_id: 509,
            label: 'XS',
            value: 687,
          },
        ],
        location: '',
        rental: 0,
        request: 0,
        unavailable_date: [],
        request_count: 0,
      },
    ],
  },
  {
    equipmentName: 'Equipment 5',
    details: [
      {
        sow: 'Scope 3',
        work_item: 'Work Item 3',
        sow_id: 3,
        work_item_id: 4,
        category_id: 25,
        counter: 2,
        date_end: '',
        date_start: '',
        description: '',
        equipment_details: [
          {
            equipment_id: 508,
            label: '',
            value: 48,
          },
          {
            equipment_id: 509,
            label: 'XS',
            value: 687,
          },
        ],
        location: '',
        rental: 0,
        request: 0,
        unavailable_date: [],
        request_count: 0,
      },
    ],
  },
  {
    equipmentName: 'Equipment 6',
    details: [
      {
        sow: 'Scope 3',
        work_item: 'Work Item 3',
        sow_id: 3,
        work_item_id: 4,
        category_id: 25,
        counter: 2,
        date_end: '',
        date_start: '',
        description: '',
        equipment_details: [
          {
            equipment_id: 508,
            label: '',
            value: 48,
          },
          {
            equipment_id: 509,
            label: 'XS',
            value: 687,
          },
        ],
        location: '',
        rental: 0,
        request: 0,
        unavailable_date: [],
        request_count: 0,
      },
    ],
  },
  {
    equipmentName: 'Equipment 7',
    details: [
      {
        sow: 'Scope 3',
        work_item: 'Work Item 3',
        sow_id: 3,
        work_item_id: 4,
        category_id: 25,
        counter: 2,
        date_end: '',
        date_start: '',
        description: '',
        equipment_details: [
          {
            equipment_id: 508,
            label: '',
            value: 48,
          },
          {
            equipment_id: 509,
            label: 'XS',
            value: 687,
          },
        ],
        location: '',
        rental: 0,
        request: 0,
        unavailable_date: [],
        request_count: 0,
      },
    ],
  },
  {
    equipmentName: 'Equipment 8',
    details: [
      {
        sow: 'Scope 3',
        work_item: 'Work Item 3',
        sow_id: 3,
        work_item_id: 4,
        category_id: 25,
        counter: 2,
        date_end: '',
        date_start: '',
        description: '',
        equipment_details: [
          {
            equipment_id: 508,
            label: '',
            value: 48,
          },
          {
            equipment_id: 509,
            label: 'XS',
            value: 687,
          },
        ],
        location: '',
        rental: 0,
        request: 0,
        unavailable_date: [],
        request_count: 0,
      },
    ],
  },
  {
    equipmentName: 'Equipment 9',
    details: [
      {
        sow: 'Scope 3',
        work_item: 'Work Item 3',
        sow_id: 3,
        work_item_id: 4,
        category_id: 25,
        counter: 2,
        date_end: '',
        date_start: '',
        description: '',
        equipment_details: [
          {
            equipment_id: 508,
            label: '',
            value: 48,
          },
          {
            equipment_id: 509,
            label: 'XS',
            value: 687,
          },
        ],
        location: '',
        rental: 0,
        request: 0,
        unavailable_date: [],
        request_count: 0,
      },
    ],
  },
  {
    equipmentName: 'Equipment 10',
    details: [
      {
        sow: 'Scope 3',
        work_item: 'Work Item 3',
        sow_id: 3,
        work_item_id: 4,
        category_id: 25,
        counter: 2,
        date_end: '',
        date_start: '',
        description: '',
        equipment_details: [
          {
            equipment_id: 508,
            label: '',
            value: 48,
          },
          {
            equipment_id: 509,
            label: 'XS',
            value: 687,
          },
        ],
        location: '',
        rental: 0,
        request: 0,
        unavailable_date: [],
        request_count: 0,
      },
    ],
  },
  {
    equipmentName: 'Equipment 11',
    details: [
      {
        sow: 'Scope 3',
        work_item: 'Work Item 3',
        sow_id: 3,
        work_item_id: 4,
        category_id: 25,
        counter: 2,
        date_end: '',
        date_start: '',
        description: '',
        equipment_details: [
          {
            equipment_id: 508,
            label: '',
            value: 48,
          },
          {
            equipment_id: 509,
            label: 'XS',
            value: 687,
          },
        ],
        location: '',
        rental: 0,
        request: 0,
        unavailable_date: [],
        request_count: 0,
      },
    ],
  },
  {
    equipmentName: 'Equipment 12',
    details: [
      {
        sow: 'Scope 3',
        work_item: 'Work Item 3',
        sow_id: 3,
        work_item_id: 4,
        category_id: 25,
        counter: 2,
        date_end: '',
        date_start: '',
        description: '',
        equipment_details: [
          {
            equipment_id: 508,
            label: '',
            value: 48,
          },
          {
            equipment_id: 509,
            label: 'XS',
            value: 687,
          },
        ],
        location: '',
        rental: 0,
        request: 0,
        unavailable_date: [],
        request_count: 0,
      },
    ],
  },
  {
    equipmentName: 'Equipment 13',
    details: [
      {
        sow: 'Scope 3',
        work_item: 'Work Item 3',
        sow_id: 3,
        work_item_id: 4,
        category_id: 25,
        counter: 2,
        date_end: '',
        date_start: '',
        description: '',
        equipment_details: [
          {
            equipment_id: 508,
            label: '',
            value: 48,
          },
          {
            equipment_id: 509,
            label: 'XS',
            value: 687,
          },
        ],
        location: '',
        rental: 0,
        request: 0,
        unavailable_date: [],
        request_count: 0,
      },
    ],
  },
  {
    equipmentName: 'Equipment 14',
    details: [
      {
        sow: 'Scope 3',
        work_item: 'Work Item 3',
        sow_id: 3,
        work_item_id: 4,
        category_id: 25,
        counter: 2,
        date_end: '',
        date_start: '',
        description: '',
        equipment_details: [
          {
            equipment_id: 508,
            label: '',
            value: 48,
          },
          {
            equipment_id: 509,
            label: 'XS',
            value: 687,
          },
        ],
        location: '',
        rental: 0,
        request: 0,
        unavailable_date: [],
        request_count: 0,
      },
    ],
  },
  {
    equipmentName: 'Equipment 15',
    details: [
      {
        sow: 'Scope 3',
        work_item: 'Work Item 3',
        sow_id: 3,
        work_item_id: 4,
        category_id: 25,
        counter: 2,
        date_end: '',
        date_start: '',
        description: '',
        equipment_details: [
          {
            equipment_id: 508,
            label: '',
            value: 48,
          },
          {
            equipment_id: 509,
            label: 'XS',
            value: 687,
          },
        ],
        location: '',
        rental: 0,
        request: 0,
        unavailable_date: [],
        request_count: 0,
      },
    ],
  },
];

const Equipment = (props) => {
  const Swal = require('sweetalert2');

  const company_id = parseInt(localStorage.getItem('company_id'));
  let accessToken = localStorage.getItem('token');
  let user_id = parseInt(localStorage.getItem('user_id'));

  let counter = -1;

  const [requestedEquipments, setRequestedEquipments] = useState({});
  const [active, setActive] = useState(1);
  const [modal, setModal] = useState(false);
  const [description, setdescription] = useState(false);
  const [dateNeeded, setdateNeeded] = useState(false);
  const [duration, setduration] = useState(false);
  const [equipmentList, setequipmentList] = useState([]);
  const [addEquipment, setaddEquipment] = useState([]);
  const [categoryList, setcategoryLIst] = useState([]);
  const [modelequipment, setmodelEquipment] = useState([]);
  const [modalEmail, setmodalEmail] = useState(false);
  const [email, setemail] = useState('');
  const [projectstart, setprojectStart] = useState('');
  const [projectend, setprojectend] = useState('');
  const [currentPage, setcurrentPage] = useState(1);
  const [postperPage, setpostperPage] = useState(10);
  const [paginateInitial, setPaginateInitial] = useState(0);
  const [paginateAction, setPaginateAction] = useState('');
  const [requestHistory, setRequestHistory] = useState([]);
  const [fuel, setFuel] = useState([]);
  const [modalBrandsList, setModalBrandsList] = useState([]);
  const [modalCategoryList, setModalCategoryList] = useState([]);
  const [categoryValue, setCategoryValue] = useState([]);
  const [requestDate, setRequestDate] = useState('');
  const [preparer, setPreparer] = useState('');
  const [preparerPosition, setPreparerPosition] = useState('');
  const [printCategory, setPrintCategory] = useState('');
  const [printPrNum, setPrintPrNum] = useState();
  const [fuelRequest, setFuelRequest] = useState({});
  const [erNumber, setErNumber] = useState('');
  const [notes, setNotes] = useState('');
  const [dateApproved, setDateApproved] = useState('');
  const [approver, setApprover] = useState('');
  const [position, setPosition] = useState('');
  let permission = useSelector((state) => state.permission);
  const [clickPrintNotif, setClickPrintNotif] = useState(false);
  const [equipmentRequest, setequipmentRequest] = useState({});
  const [toggle, settoggle] = useState([]);

  useEffect(() => {
    // setequipmentList(equipment); // please comment this line and uncomment bellow this line
    setFuel(fuel_data);
    setprojectStart(new Date(props.project_start));
    setprojectend(new Date(props.project_end));
    // setequipmentList(equipmentData);
    gitequipment(props.id); //commented to use the equipmentData as dummy data
    getequipmentall();
    gitRequstHistory(props.id);
    CategoryList();
    BrandList();
    gitRequstHistory();
    requestFuelTotalorder(props.id); // NOTE: for getting total order per fuel category
  }, [props]);

  //uncomment gitequipent function and change the route

  if ('matchMedia' in window) {
    // Chrome, Firefox, and IE 10 support mediaMatch listeners
    window.matchMedia('print').addListener(function (media) {
      if (media.matches) {
        beforePrint();
      } else {
        // Fires immediately, so wait for the first mouse movement
        afterPrint();
      }
    });
  } else {
  }

  const beforePrint = () => {
    setClickPrintNotif(true);
  };

  const afterPrint = () => {
    setClickPrintNotif(false);
  };

  const indexOfLastPost = currentPage * postperPage;
  const indexOfFirstPost = indexOfLastPost - postperPage;
  // const currentPost = equipmentList.slice(indexOfFirstPost, indexOfLastPost);
  const currentPost = equipmentList.slice(indexOfFirstPost, indexOfLastPost);
  let pageNumbers = [];
  let paginateNumber = [];

  for (let i = 1; i <= Math.ceil(equipmentList.length / postperPage); i++) {
    // paginateNumber.push(i);
    if (i <= 5 && paginateAction == '') {
      pageNumbers.push(i);
    } else if (paginateAction == 'next') {
      if (i > paginateInitial && i <= paginateInitial + 5) {
        pageNumbers.push(i);
      }
    } else if (paginateAction == 'prev') {
      console.log(paginateInitial, 'prev');
      if (i <= paginateInitial) {
        pageNumbers.push(i);
      }
    }
  }

  const paginate = (pageNumber) => setcurrentPage(pageNumber);

  const action = (val) => {
    if (val == 'next') {
      let num = paginateInitial + 5;
      setPaginateInitial(num);
      setPaginateAction(val);
    } else {
      let numTwo = paginateInitial - 5;

      setPaginateInitial(numTwo);
      setPaginateAction(numTwo == 0 ? '' : val);
    }
  };

  const PaginateButton = pageNumbers.map((number, Index) => {
    return (
      <li key={number} className="page-item">
        <a onClick={() => paginate(number)} className={`page-link ${number == currentPage ? ' active-paginate' : ''}`}>
          <span className={` ${number == currentPage ? ' font-color-active' : ''}`}>{number}</span>
        </a>
      </li>
    );
  });

  const requestFuelTotalorder = (projectId) => {
    GetTotalFuelOrder(projectId).then((res) => {
      if (res.data.status == 200) {
        let fuelResponse = res.data.response;
        let fuelData = JSON.parse(JSON.stringify(fuel_data)); // deep copy
        for (let i = 0; i < fuelData.length; i++) {
          fuelData[i].total_ordered = fuelResponse[fuelData[i].category] || 0;
        }
        setFuel(fuelData);
      }
    });
  };

  const gitRequstHistory = () => {
    const company_id = parseInt(localStorage.getItem('company_id'));
    $axios.get(`equipment/request-history/${company_id}/${props.id}?limit=${-1}`).then((res) => {
      console.log(res);
      if (res.data.status == 200) {
        setRequestHistory(res.data.response.data);
      }
    });
  };

  const gitequipment = (project_id) => {
    const company_id = parseInt(localStorage.getItem('company_id'));
    $axios
      .get(`equipment/project/list/${company_id}/${project_id}`, {
        // project_id,
        // company_id: company_id,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          setequipmentList(res.data.response);
        }
      })
      .catch((error) => {
        console.log('projectdetails/Equipment.js', ' ', 'line 135', error);
      });
  };

  const CategoryList = () => {
    let company_id = parseInt(localStorage.getItem('company_id'));
    $axios
      .post(IP + 'equipment/category', {
        company_id: company_id,
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        console.log(res);
        setModalCategoryList(res.data.response);
      })
      .catch((error) => {
        console.log('projectdetails/Equipment.js', ' ', 'line 135', error);
      });
  };

  const BrandList = () => {
    const company_id = parseInt(localStorage.getItem('company_id'));
    $axios
      .post('equipment/list/brands', {
        company_id: company_id,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        console.log(res);
        setModalBrandsList(res.data.response);
      })
      .catch((error) => {
        console.log('projectdetails/Equipment.js', ' ', 'line 135', error);
      });
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // NOTE: method = "cinput" or "cselect"
  const descriptionOnchage = (e, counter, cat_id, method = 'cinput') => {
    // old code --

    // let arr = [...equipmentList];
    // let Index = arr.findIndex((a) => a.counter == counter);
    // // let val = e.target.value; --> brand id
    // let val = e.target.value; // equipment id

    // old code --

    let updatedEquipmentList = [...equipmentList];
    console.log(updatedEquipmentList, 'updatedList initial');
    console.log('counter', counter);
    let index = updatedEquipmentList.findIndex((item) => item.details.some((detail) => detail.counter === counter));
    console.log('index', index);
    let value = e.target.value;

    // console.log('arr descriptionOnchage', arr);

    if (method === 'cselect') {
      $axios({
        method: 'get',
        url: IP + `equipment/project/details/${company_id}/${props.id}?id=${value}`,
        headers: {
          'Content-Type': 'application/vnd.myapp.type+json',
          // Authorization: "Bearer " + accessToken,

          Authorization: `Bearer ${accessToken}`,
        },
      }).then((res) => {
        console.log(res, 'res test');
        if (res.data.status == 1) {
          // res.data.response.unavailable_date.forEach((date) => {
          //   arr[Index].unavailable_date.push(date);
          // });

          // old code--
          // arr[Index].description = val;
          // arr[Index].brand = res.data.response.brand;
          // arr[Index].brand_id = res.data.response.brand_id;
          // arr[Index].equipment_id = res.data.response.equipment_id;
          // arr[Index].location = res.data.response.location;
          // arr[Index].rental = res.data.response.rental;
          // arr[Index].unavailable_date = res.data.response.unavailable_date;
          // console.log(arr, 'rental');
          // setequipmentList(arr);
          // old code--

          let updatedDetails = updatedEquipmentList[index].details.map((detail) => {
            if (detail.counter === counter) {
              return {
                ...detail,
                description: value,
                brand: res.data.response.brand,
                brand_id: res.data.response.brand_id,
                equipment_id: res.data.response.equipment_id,
                location: res.data.response.location,
                rental: res.data.response.rental,
                unavailable_date: res.data.response.unavailable_date,
              };
            }
            return detail;
          });

          let updatedItem = {
            ...updatedEquipmentList[index],
            details: updatedDetails,
          };

          let updatedList = [...updatedEquipmentList];
          updatedList[index] = updatedItem;

          console.log(updatedList, 'updatedList');
          setequipmentList(updatedList);
        }
      });
    } else if (method === 'cinput') {
      // arr[Index].description = val;
      // arr[Index].brand = val;
      // arr[Index].brand_id = -1;
      // arr[Index].equipment_id = -1;
      // arr[Index].location = '';
      // setequipmentList(arr);
    }
  };

  const rentalOnchange = (e, counter) => {
    let arr = [...equipmentList];
    let Index = arr.findIndex((a) => a.counter == counter);
    arr[Index].rental = e.target.checked ? 1 : 0;

    setequipmentList(arr);
  };

  const requestOnchange = (e, counter) => {
    // old code --
    // let arr = [...equipmentList];
    // let Index = arr.findIndex((a) => a.counter == counter);
    // console.log(arr[Index], 'Index');
    // console.log(e, 'e.target.checked');
    // // arr[Index].request = e.target.checked == true ? 1 : 0;
    // arr[Index].request = e.target.checked ? 1 : 0;

    // // setequipmentList(arr);
    // const updatedEquipmentList = arr.map((item) => {
    //   if (item.counter === counter) {
    //     return { ...item, checked: e.target.checked };
    //   }
    //   return item;
    // });

    // console.log(updatedEquipmentList, 'updatedEquipmentList');
    // setequipmentList(updatedEquipmentList);
    // old code --

    let updatedEquipmentList = [...equipmentList];
    let index = updatedEquipmentList.findIndex((item) => item.details.some((detail) => detail.counter === counter));
    let value = e.target.checked ? 1 : 0;

    let updatedDetails = updatedEquipmentList[index].details.map((detail) => {
      if (detail.counter === counter) {
        return {
          ...detail,
          request: value,
        };
      }
      return detail;
    });

    let updatedItem = {
      ...updatedEquipmentList[index],
      details: updatedDetails,
    };

    let updatedList = [...updatedEquipmentList];
    updatedList[index] = updatedItem;
    console.log(updatedList, 'updatedList');
    setequipmentList(updatedList);
  };

  const datestartOnchange = (dates, counter) => {
    // old code--
    // let year = dates.getFullYear();
    // let month = dates.getMonth();
    // let date = dates.getDate();
    // let arr = [...equipmentList];
    // let Index = arr.findIndex((a) => a.counter == counter);
    // arr[Index].date_start = year + '-' + (month + 1) + '-' + date;

    // setequipmentList(arr);
    // old code--

    let year = dates.getFullYear();
    let month = dates.getMonth();
    let date = dates.getDate();
    let updatedEquipmentList = [...equipmentList];
    let index = updatedEquipmentList.findIndex((item) => item.details.some((detail) => detail.counter === counter));

    let updatedDetails = updatedEquipmentList[index].details.map((detail) => {
      if (detail.counter === counter) {
        return {
          ...detail,
          date_start: year + '-' + (month + 1) + '-' + date,
        };
      }
      return detail;
    });

    let updatedItem = {
      ...updatedEquipmentList[index],
      details: updatedDetails,
    };

    let updatedList = [...updatedEquipmentList];
    updatedList[index] = updatedItem;
    console.log('datestartOnchange updatedList', updatedList);
    setequipmentList(updatedList);
  };

  const dateendOnchange = (dates, counter) => {
    // old code--
    // let year = dates.getFullYear();
    // let month = dates.getMonth();
    // let date = dates.getDate();
    // let arr = [...equipmentList];
    // let Index = arr.findIndex((a) => a.counter == counter);
    // arr[Index].date_end = year + '-' + (month + 1) + '-' + date;

    // setequipmentList(arr);
    // old code--

    let year = dates.getFullYear();
    let month = dates.getMonth();
    let date = dates.getDate();
    let updatedEquipmentList = [...equipmentList];
    let index = updatedEquipmentList.findIndex((item) => item.details.some((detail) => detail.counter === counter));

    let updatedDetails = updatedEquipmentList[index].details.map((detail) => {
      if (detail.counter === counter) {
        return {
          ...detail,
          date_end: year + '-' + (month + 1) + '-' + date,
        };
      }
      return detail;
    });

    let updatedItem = {
      ...updatedEquipmentList[index],
      details: updatedDetails,
    };

    let updatedList = [...updatedEquipmentList];
    updatedList[index] = updatedItem;
    console.log('datestartOnchange updatedList', updatedList);
    setequipmentList(updatedList);
  };

  const subnewequipment = () => {
    let sample = categoryValue.label;
    console.log(modalBrandsList[`${sample}`]);

    let arr = [...equipmentList];
    let arr_length = equipmentList.length;
    console.log(categoryValue);
    arr.push({
      category_id: categoryValue.value,
      counter: arr_length + 1,
      equipment: categoryValue.label,
      description: '',
      rental: 0,
      location: '',
      date_start: '',
      date_end: '',
      request: 0,
      equipment_details: typeof modalBrandsList[`${sample}`] == 'undefined' ? [] : modalBrandsList[`${sample}`],
      unavailable_date: [],
    });

    setequipmentList(arr);

    setModal(false);
  };

  const resetFuelState = () => {
    const initialFuelState = fuel.map((item) => ({
      ...item,
      liters: null, // Reset liters to empty string
      unit_price: null, // Reset unit_price to empty string
      total_price: null, // You can reset total_price if needed
      supplier: null, // Reset supplier to empty string
      date_deployment: null, // Reset date_deployment to empty string
      notes: null, // Reset notes to empty string
    }));

    setFuel(initialFuelState); // Assuming you are using useState to manage fuel state
  };

  const createarequest = (type) => {
    Swal.fire({
      title: 'Do you want to save the changes?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Save',
      confirmButtonColor: '#23303d',
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          project_id: parseInt(props.id),
          company_id: company_id,
          user_id: user_id,
          type: type,
          request: [],
        };
        if (type == 'Fuel') {
          let arr = [...fuel];

          let filter = arr.filter((a) => a.liters != null && a.unit_price != null);
          for (var i in filter) {
            data.request.push({
              category: filter[i].category,
              liters: parseFloat(filter[i].liters),
              unit_price: parseFloat(filter[i].unit_price),
              total_price: filter[i].total_price,
              supplier: filter[i].supplier,
              date_deployment: filter[i].date_deployment,
              notes: filter[i].notes,
            });
          }
        } else {
          let arr = [...equipmentList];
          let request = { ...requestedEquipments };
          let requestData = [];

          for (let index in request) {
            for (let detailIndex in request[index]) {
              if (request[index][detailIndex].isChecked) {
                const equipmentIndex = request[index][detailIndex].index;

                requestData.push({
                  brand: arr[index].details[detailIndex].equipment_details[equipmentIndex].label,
                  brand_id: arr[index].details[detailIndex].equipment_details[equipmentIndex].value,
                  category: arr[index].equipmentName,
                  category_id: arr[index].details[detailIndex].category_id,
                  date_end: arr[index].details[detailIndex].date_end,
                  date_start: arr[index].details[detailIndex].date_start,
                  equipment_id: arr[index].details[detailIndex].equipment_details[equipmentIndex].equipment_id,
                  rental: arr[index].details[detailIndex].rental,
                  psow_id: arr[index].details[detailIndex].psow_id,
                  pe_id: arr[index].details[detailIndex].pe_id,
                });
              }
            }
          }
          data.request = requestData;

          // console.log(equipmentList, 'equipment_list')
          // let filter = arr.filter((e) => e.request == 1);
          // for (var i in filter) {
          //   console.log(filter[i].date_start);
          //   console.log(filter[i]);
          //   data.request.push({
          //     // description: filter[i].description == "" ? -1 : filter[i].description,
          //     // description: filter[i].description,
          //     brand: 'brand' in filter[i] ? filter[i].brand : '',
          //     brand_id: 'brand_id' in filter[i] ? filter[i].brand_id : -1,
          //     equipment_id: 'equipment_id' in filter[i] ? filter[i].equipment_id : -1,
          //     category: filter[i].equipment,
          //     category_id: filter[i].category_id,
          //     date_start: filter[i].date_start,
          //     date_end: filter[i].date_end,
          //     rental: parseInt(filter[i].rental),
          //   });
          // }
        }

        $axios
          .post('equipment/request', {
            data,
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((res) => {
            if (res.data.status != 200) {
              console.log(res.data.message, 'errror message');
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: res.data.message,
                confirmButtonColor: '#23303D',
                // footer: '<a href="">Why do I have this issue?</a>',
              });
            } else {
              resetFuelState();
              gitequipment(props.id);
              requestFuelTotalorder(props.id);
              gitRequstHistory();
              setActive(3);
              Swal.fire('Saved!', '', 'success');
              setRequestedEquipments({}); // reset
            }
          })

          .catch((error) => {
            console.log('projectdetails/Equipment.js', ' ', 'line 367', error);
          });
      } else if (result.isDenied) {
        Swal.fire('Changes are not saved', '', 'info');
      }
    });
  };

  const saveDateDelivered = (e, index, id, pr_num, remarks) => {
    // if (e.key == "Enter") {
    let data = {
      id: id,
      pr_num: pr_num,
      updates: {
        remarks: remarks,
      },
    };

    EquipmentRequestHistoryEdit(company_id, props.id, data).then((res) => {
      if (res.data.status == 1) {
        let arr = [...requestHistory];

        arr[index]['edit'] = false;

        setRequestHistory(arr);
      }
    });
    // }
  };

  const validateInput = (enteredValue) => {
    // Check if the entered value is a positive number and does not contain scientific notation
    const isValid = enteredValue === '' || (/^\d*\.?\d*$/.test(enteredValue) && parseFloat(enteredValue) >= 0);
    const hasScientificNotation = /[eE]/.test(enteredValue);

    return isValid && !hasScientificNotation;
  };

  const fuelQuantityChange = (e, Index) => {
    let arr = [...fuel];
    const isValid = validateInput(e.target.value);
    console.log(isValid, 'isValid');
    if (isValid) {
      arr[Index].liters = e.target.value;
      setFuel(arr);
    } else {
      arr[Index].liters = 0;
      Swal.fire({ icon: 'error', title: 'Invalid input', text: 'Please enter a valid positive number' });
      setFuel(arr);
    }
  };

  const unitPriceChange = (e, Index) => {
    let arr = [...fuel];
    const isValid = validateInput(e.target.value);
    if (isValid) {
      arr[Index].unit_price = e.target.value;
      setFuel(arr);
    } else {
      arr[Index].unit_price = 0;
      Swal.fire({ icon: 'error', title: 'Invalid input', text: 'Please enter a valid positive number' });
      setFuel(arr);
    }
  };

  // const fuelQuantityChange = (e, Index) => {
  //   let arr = [...fuel];
  //   arr[Index].liters = e.target.value;

  //   setFuel(arr);
  // };

  // const unitPriceChange = (e, Index) => {
  //   let arr = [...fuel];
  //   arr[Index].unit_price = e.target.value;

  //   setFuel(arr);
  // };

  const supplierChange = (e, Index) => {
    let arr = [...fuel];
    arr[Index].supplier = e.target.value;

    setFuel(arr);
  };

  const deploymnentDateChange = (e, Index) => {
    let arr = [...fuel];
    arr[Index].date_deployment = e.target.value;

    setFuel(arr);
  };

  const notesChange = (e, Index) => {
    let arr = [...fuel];
    arr[Index].notes = e.target.value;

    setFuel(arr);
  };

  const FuelTable = fuel.map((fuel, Index) => {
    let total_price = (fuel.liters * fuel.unit_price).toFixed(2);
    return (
      <tr key={Index}>
        <td>{fuel.category}</td>
        <td>{fuel.total_ordered}</td>
        <td>
          <CInput type="text" inputMode="numeric" value={fuel.liters} onChange={(e) => fuelQuantityChange(e, Index)} />
        </td>
        <td>
          <CInput type="text" inputMode="numeric" value={fuel.unit_price} onChange={(e) => unitPriceChange(e, Index)} />
        </td>
        <td>{total_price}</td>
        <td>
          <CInput type="text" value={fuel.supplier} onChange={(e) => supplierChange(e, Index)} />
        </td>
        <td>
          <CInput type="date" value={fuel.date_deployment} onChange={(e) => deploymnentDateChange(e, Index)} />
        </td>
        <td>
          <CInput type="text" value={fuel.notes} onChange={(e) => notesChange(e, Index)} />
        </td>
      </tr>
    );
  });

  {
    /** OLD CODE */
  }
  // const ListEquipment = currentPost.map((item, Index) => {
  //   let startDate = item.date_start == '' ? item.date_start : new Date(Date.parse(item.date_start));
  //   let endDate = item.date_end == '' ? item.date_end : new Date(Date.parse(item.date_end));
  //   let arr_date = [...item.unavailable_date];
  //   let arr_futa = [];
  //   for (var i in arr_date) {
  //     let start_futa = new Date(arr_date[i].date_start);
  //     let end_futa = new Date(arr_date[i].date_end);

  //     while (start_futa <= end_futa) {
  //       arr_futa.push(new Date(start_futa)); // jan 01 2022
  //       start_futa.setDate(start_futa.getDate() + 1);

  //       // jan 1 - 5
  //     }
  //   }

  //   let rental = '';
  //   if (typeof equipment.rental == 'undefined') {
  //     rental = '';
  //   } else {
  //     if (equipment.rental == 0) {
  //       rental = '';
  //     } else {
  //       rental = 'Yes';
  //     }
  //   }

  //   const option = item.equipment_details.map((equipment, E_index) => {
  //     return (
  //       // NOTE: brand id nang value. equipment_id ang need sa request equipment/project/details
  //       // <option value={equipment.value} key={E_index}>
  //       <option value={equipment.equipment_id} key={E_index}>
  //         {equipment.label}
  //       </option>
  //     );
  //   });

  //   if (item.equipment_details.length !== 0) {
  //     return (
  //       <tr key={Index}>
  //         <td style={{ textAlign: 'center' }}>
  //           <CInputCheckbox
  //             id={`checkbox2_counter${item.counter}`}
  //             color="aimhi-textlight-neutral"
  //             name="checkbox2"
  //             onChange={(e) => requestOnchange(e, item.counter)}
  //             // value={item.request == 1 ? "checked" : ""}
  //             checked={item.checked}
  //             disabled={permission['update'][0] != 1}
  //           />
  //         </td>
  //         <td>{item.equipment}</td>
  //         <td>
  //           {/* onChangeModel here */}
  //           <CSelect size="sm" style={{ width: '150px' }} value={item.description} onChange={(e) => descriptionOnchage(e, item.counter, item.category_id, 'cselect')}>
  //             <option value="">Select</option>
  //             {option}
  //           </CSelect>
  //           {/* {item.equipment_details.length == 0 ? (
  //             <CInput
  //               type="text"
  //               size="sm"
  //               value={item.description}
  //               onChange={(e) =>
  //                 descriptionOnchage(e, item.counter, item.category_id, "cinput")
  //               }
  //             />
  //           ) : (
  //             <CSelect
  //               size="sm"
  //               style={{ width: "150px" }}
  //               value={item.description}
  //               onChange={(e) =>
  //                 descriptionOnchage(e, item.counter, item.category_id, "cselect")
  //               }
  //             >
  //               <option value="">Select</option>
  //               {option}
  //             </CSelect>
  //           )} */}
  //         </td>

  //         <td>{item.location}</td>
  //         <td>
  //           <DatePicker
  //             selected={startDate}
  //             onChange={(date) => datestartOnchange(date, item.counter)}
  //             // onChange={(date) => setStartDate(date)}
  //             selectsStart
  //             startDate={startDate}
  //             endDate={endDate}
  //             excludeDates={arr_futa}
  //             minDate={projectstart}
  //             // includeDateIntervals={[{ start: projectstart, end: projectend }]}
  //             placeholderText="mm/dd/yyyy"
  //             disabled={permission['update'][0] != 1}
  //           />
  //           {/* <CInput
  //             type="date"
  //             onChange={(e) => datestartOnchange(e, Index)}
  //             value={item.date_start}
  //             size="sm"
  //             style={{ width: "140px" }}
  //             // min="2019-06-02"
  //             // max="2019-06-08"
  //           /> */}
  //         </td>
  //         <td>
  //           <DatePicker
  //             selected={endDate}
  //             onChange={(date) => dateendOnchange(date, item.counter)}
  //             // onChange={(date) => setStartDate(date)}
  //             selectsEnd
  //             startDate={startDate}
  //             endDate={endDate}
  //             // minDate={startDate}
  //             excludeDates={arr_futa}
  //             minDate={projectstart}
  //             // includeDateIntervals={[{ start: projectstart, end: projectend }]}
  //             placeholderText="mm/dd/yyyy"
  //             disabled={permission['update'][0] != 1}
  //           />
  //           {/* <CInput
  //             type="date"
  //             onChange={(e) => dateendOnchange(e, Index)}
  //             value={item.date_end}
  //             size="sm"
  //             style={{ width: "140px" }}
  //           /> */}
  //         </td>
  //         {/* <td>{`${rental}`}</td> */}
  //         <td>{item.rental == 1 ? 'YES' : ''}</td>
  //         {/* <td style={{ textAlign: "center" }}>
  //           <CInputCheckbox
  //             id="checkbox2"
  //             color="aimhi-textlight-neutral"
  //             name="checkbox2"
  //             onChange={(e) => rentalOnchange(e, item.counter)}
  //             value={item.rental}
  //             disabled={permission["update"][0] != 1}
  //           />
  //         </td> */}
  //       </tr>
  //     );
  //   }
  // });

  {
    /** OLD CODE */
  }

  const Add = () => {
    let arr = [...addEquipment];

    arr.push('');

    // setdescription(false);
    // setdateNeeded(false);
    // setduration(false);
    setaddEquipment(arr);
  };

  const EquipmentOnchange = (opt, meta) => {
    setCategoryValue(opt);
  };

  const createOptionEquipment = (opt, meta) => {
    let arr = [...modalCategoryList];
    // arr.push({ label: opt, value: opt });
    // setCategoryValue({ label: opt, value: opt });
    arr.push({ label: opt, value: -1 });
    setCategoryValue({ label: opt, value: -1 });
    setModalCategoryList(arr);
  };

  const equipmentOnchange = (e, Index) => {
    let arr = [...addEquipment];

    arr[Index] = e.target.value;

    // setdescription(true);

    setaddEquipment(arr);
  };

  // const descriptionOnchange = (e, Index) => {
  //   let arr = [...addEquipment];

  //   arr[Index].description = e.target.value;

  //   // setdescription(true);

  //   setaddEquipment(arr);
  // };

  // const dateStart = (e, Index) => {
  //   let arr = [...addEquipment];

  //   arr[Index].date_start = e.target.value;

  //   setaddEquipment(arr);
  // };

  // const dateEnd = (e, Index) => {
  //   let arr = [...addEquipment];
  //   arr[Index].date_end = e.target.value;

  //   setaddEquipment(arr);
  // };

  // const EquipmentAddList = addEquipment.map((item, Index) => {
  //   const option = item.equipment_details.map((equipment, E_Index) => {
  //     return (
  //       <option key={E_Index} value={equipment.value}>
  //         {equipment.label}
  //       </option>
  //     );
  //   });

  //   const option_two = item.description_details.map((details, D_Index) => {
  //     return (
  //       <option key={D_Index} value={details.value}>
  //         {details.label}
  //       </option>
  //     );
  //   });

  //   return (
  //     <tr>
  //       <td>
  //         <CSelect
  //           size="sm"
  //           onChange={(e) => equipmentOnchange(e, Index)}
  //           value={item.equipment}
  //         >
  //           <option value="">Select..</option>
  //           {option}
  //         </CSelect>
  //       </td>
  //       <td>
  //         <CSelect
  //           size="sm"
  //           onChange={(e) => descriptionOnchange(e, Index)}
  //           value={item.description}
  //         >
  //           <option value="">Select..</option>
  //           {option_two}
  //         </CSelect>
  //       </td>
  //       <td>
  //         <CInput
  //           type="date"
  //           onChange={(e) => dateStart(e, Index)}
  //           value={item.date_start}
  //         />
  //       </td>
  //       <td>
  //         <CInput
  //           type="date"
  //           onChange={(e) => dateEnd(e, Index)}
  //           value={item.date_end}
  //         />
  //       </td>
  //     </tr>
  //   );
  // });

  const send = () => {
    // const receiver = email
    $axios
      .post('equipment/sentmail', {
        receiver: email,
        equipment_request: equipmentRequest,
      })
      .then(function (response) {
        if (response.data.status == 1) {
          setmodalEmail(false);
          Swal.fire({
            icon: 'success',
            title: 'Email already sent',
            showConfirmButton: false,
          });
        } else {
        }
      })
      .catch((error) => {
        console.log('projectdetails/Equipment.js', ' ', 'line 613', error);
      });
  };

  const getequipmentall = () => {
    const company_id = parseInt(localStorage.getItem('company_id'));
    $axios
      .post('equipment/list/brands', {
        company_id: company_id,
      })
      .then((res) => {
        setmodelEquipment(res.data.response);

        let arr = Object.keys(res.data.response);

        setcategoryLIst(arr);
      })
      .catch((error) => {
        console.log('projectdetails/Equipment.js', ' ', 'line 631', error);
      });
  };

  const clickPrint = (
    project_id,
    pr_number,

    category,
  ) => {
    const company_id = parseInt(localStorage.getItem('company_id'));
    $axios.get(`equipment/request-history/details/${company_id}/${project_id}/${pr_number}?category=${category}`).then((res) => {
      setPrintCategory(category);
      // setPrintPrNum(equipment_res.pr_num);

      if (category == 'Fuel') {
        setFuelRequest(res.data.response.data);
      } else {
        setequipmentRequest(res.data.response.data);
      }

      // setPreparer(res.data.response.preparer);
      // setPreparerPosition(res.data.response.preparer_position);
      // setRequestDate(res.data.response.date_requested);
      // setErNumber(res.data.response.pr_num);
      // setNotes(res.data.response.notes);
      // setApprover(res.data.response.approver);
      // setDateApproved(res.data.response.date_approved);
      // setPosition(res.data.response.position);
    });

    setActive(4);
  };

  const clickEdit = (Index) => {
    let arr = [...requestHistory];
    arr[Index]['edit'] = true;
    setRequestHistory(arr);
  };

  const remarks = (e, Index) => {
    let arr = [...requestHistory];
    arr[Index]['remarks'] = e.target.value;
    setRequestHistory(arr);
  };

  const ErHistory = requestHistory.map((data, Index) => {
    let color = '#25C6E3';
    if (data['approval_status'] == 'Approved') {
      color = '#3A7D21';
    } else if (data['approval_status'] == 'Disapproved') {
      color = '#B42A19';
    }
    return (
      <tr key={Index}>
        <td>{Index + 1}</td>
        <td>{data.pr_number}</td>
        <td>{data.category}</td>
        <td>{data.preparer}</td>
        <td>{data.request_date}</td>
        <td>
          <CCol style={{ padding: '0' }}>
            <CRow>
              <CCol xl="10">
                <CTextarea
                  onChange={(e) => remarks(e, Index)}
                  onBlur={(e) => saveDateDelivered(e, Index, data.id, data.pr_number, data.remarks)}
                  onKeyPress={(e) => {
                    if (e.key == 'Enter') {
                      saveDateDelivered(e, Index, data.id, data.pr_number, data.remarks);
                    }
                  }}
                  onClick={(e) => {
                    console.log(data['date_delivered']);
                  }}
                  value={data['remarks']}
                  disabled={data.edit != true}
                ></CTextarea>
              </CCol>
              <CCol xl="2">
                <FaPencilAlt onClick={() => clickEdit(Index)} />
              </CCol>
            </CRow>
          </CCol>
        </td>
        <td style={{ color: color }}>{data['approval_status']}</td>
        <td>
          <CButton
            variant="outline"
            block
            color="aimhi-primary"
            onClick={() =>
              clickPrint(
                props.project_id,
                data.pr_number,
                data.category,

                // props.project_id,
                // data.pr_number,
                // data.preparer,
                // data.request_date,
                // data.category,
                // data.notes
              )
            }
          >
            Print
          </CButton>
        </td>
      </tr>
    );
  });

  const toggleShow = (value) => {
    const shownState = toggle.slice();
    const index = shownState.indexOf(value);
    if (index >= 0) {
      shownState.splice(index, 1);
      settoggle(shownState);
    } else {
      shownState.push(value);
      settoggle(shownState);
    }
  };

  const updateRequestedEquipments = ({ index, detailIndex, equipmentDetailIndex, type, isChecked = false }) => {
    let request = { ...requestedEquipments };

    if (!(index in request)) {
      request[index] = {};
    }

    if (!(detailIndex in request[index])) {
      request[index][detailIndex] = {};
    }

    if (type == 'brand') {
      request[index][detailIndex]['index'] = equipmentDetailIndex;
    } else if (type == 'checkbox') {
      request[index][detailIndex].isChecked = isChecked;
    }

    setRequestedEquipments(request);
  };

  const listOfEquipments = equipmentList.map((item, Index) => {
    const tbody = item.details.map((detail, detailIndex) => {
      let startDate = detail.date_start == '' ? detail.date_start : new Date(Date.parse(detail.date_start));
      let endDate = detail.date_end == '' ? detail.date_end : new Date(Date.parse(detail.date_end));
      let arr_date = [...detail.unavailable_date];
      let arr_futa = [];
      for (var i in arr_date) {
        let start_futa = new Date(arr_date[i].date_start);
        let end_futa = new Date(arr_date[i].date_end);
        while (start_futa <= end_futa) {
          arr_futa.push(new Date(start_futa)); // jan 01 2022
          start_futa.setDate(start_futa.getDate() + 1);
          // jan 1 - 5
        }
      }
      // let rental = '';
      // if (typeof equipment.rental == 'undefined') {
      //   rental = '';
      // } else {
      //   if (equipment.rental == 0) {
      //     rental = '';
      //   } else {
      //     rental = 'Yes';
      //   }
      // }

      console.log(detail.equipment_details, 'equipment_deets');

      return (
        <tr key={detailIndex}>
          <td style={{ textAlign: 'center' }}>
            {' '}
            {/* <CInputCheckbox
              id={`checkbox2_counter${detail.counter}`}
              color="aimhi-textlight-neutral"
              name="checkbox2"
              onChange={(e) => requestOnchange(e, detail.counter)}
              // value={item.request == 1 ? "checked" : ""}
              checked={detail.checked}
              disabled={permission['update'][0] != 1}
            /> */}
            <input
              type="checkbox"
              onChange={(e) => {
                requestOnchange(e, detail.counter);
                updateRequestedEquipments({ index: Index, detailIndex: detailIndex, type: 'checkbox', isChecked: e.target.checked });
              }}
              disabled={permission['update'][0] != 1}
            />
          </td>
          <td>{detail.sow}</td>
          <td>{detail.work_item}</td>
          <td>
            {' '}
            <CSelect
              size="sm"
              style={{ width: '150px' }}
              value={detail.description}
              onChange={(e) => {
                descriptionOnchage(e, detail.counter, detail.category_id, 'cselect');
                updateRequestedEquipments({ index: Index, detailIndex: detailIndex, equipmentDetailIndex: e.target.selectedIndex - 1, type: 'brand' });
              }}
            >
              <option value="">Select</option>
              {detail.equipment_details.map((equipmentDetail, optionIndex) => (
                <option value={equipmentDetail.equipment_id} key={optionIndex}>
                  {equipmentDetail.label}
                </option>
              ))}
            </CSelect>
          </td>
          <td>{detail.location}</td>
          <td>
            {' '}
            <DatePicker
              selected={startDate}
              onChange={(date) => datestartOnchange(date, detail.counter)}
              // onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              excludeDates={arr_futa}
              minDate={projectstart}
              // includeDateIntervals={[{ start: projectstart, end: projectend }]}
              placeholderText="mm/dd/yyyy"
              disabled={permission['update'][0] != 1}
            />
          </td>
          <td>
            {' '}
            <DatePicker
              selected={endDate}
              onChange={(date) => dateendOnchange(date, detail.counter)}
              // onChange={(date) => setStartDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              // minDate={startDate}
              excludeDates={arr_futa}
              minDate={projectstart}
              // includeDateIntervals={[{ start: projectstart, end: projectend }]}
              placeholderText="mm/dd/yyyy"
              disabled={permission['update'][0] != 1}
            />
          </td>
          <td style={{ textAlign: 'center' }}>{detail.rental === 1 ? 'Yes' : 'No'}</td>
          <td style={{ textAlign: 'center' }}>{detail.request_count}</td>
        </tr>
      );
    });
    return (
      <CCard key={Index}>
        <CCardHeader onClick={() => toggleShow(item.equipmentName)}>
          <span>{toggle.includes(item.equipmentName) ? <FaAngleDown style={{ width: '25px', height: '25px' }} /> : <FaAngleRight style={{ width: '25px', height: '25px' }} />}</span>
          <span style={{ fontFamily: 'Roboto', fontWeight: 'bold', marginLeft: '10px' }}>{item.equipmentName}</span>
        </CCardHeader>
        <CCollapse show={toggle.includes(item.equipmentName)}>
          <CCardBody style={{ maxHeight: '500px', overflow: 'auto' }}>
            <table className="table">
              <thead>
                <tr>
                  <th>Request</th>
                  <th>Scope of Work</th>
                  <th>Work Item</th>
                  <th>Model</th>
                  <th>Location</th>
                  <th>Date Start</th>
                  <th>Date End</th>
                  <th>Rental</th>
                  <th>Requests</th>
                </tr>
              </thead>
              {/* <tbody>{tbody}</tbody> */}
              {/* <tbody>{ListEquipment}</tbody> */}
              <tbody>{tbody}</tbody>
            </table>
          </CCardBody>
        </CCollapse>
      </CCard>
    );
  });

  if (clickPrintNotif == true) {
    return (
      <CCol>
        <span style={{ fontSize: '25px', fontWeight: 'Bold' }}>Please use print request button to print!!</span>
      </CCol>
    );
  }

  return (
    <>
      <CRow>
        <CCol xs="12" sm="12" md="12" lg="12" xl="9" style={{ marginTop: '20px' }}>
          <CTabs>
            <CNav variant="tabs" style={{ borderWidth: '5px' }}>
              <CNavItem>
                <CNavLink className={active == 1 ? 'nav-design-active' : 'nav-design'} onClick={() => setActive(1)}>
                  Equipment
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink className={active == 2 ? 'nav-design-active' : 'nav-design'} onClick={() => setActive(2)}>
                  Fuel
                </CNavLink>
              </CNavItem>
              <CNavItem>
                <CNavLink className={active == 3 || active == 4 ? 'nav-design-active' : 'nav-design'} onClick={() => setActive(3)}>
                  Request History
                </CNavLink>
              </CNavItem>
            </CNav>
            {active == 1 && (
              <CCol style={{ marginTop: '20px' }}>
                <CCol xs="12" sm="12" md="12" lg="12" style={{ marginTop: '20px' }}>
                  <CRow>
                    <CCol xs="12" sm="12" md="12" lg="10"></CCol>
                    {/* <CCol xs="12" sm="12" md="12" lg="2">
                      {permission["create"][0] == 1 && (
                        <CButton
                          variant="outline"
                          color="aimhi-textlight-neutral"
                          size="sm"
                          onClick={() => setModal(!modal)}
                          block
                        >
                          Add Equipment
                        </CButton>
                      )}
                    </CCol> */}
                    <CCol xs="12" sm="12" md="12" lg="2">
                      {(permission['update'][0] == 1 || permission['create'][0] == 1) && (
                        <CButton block color="aimhi-primary" size="sm" onClick={() => createarequest('Equipment')}>
                          Create request
                        </CButton>
                      )}
                    </CCol>
                  </CRow>
                </CCol>
                <CCol xs="12" sm="12" md="12" lg="12" style={{ padding: '10px', marginTop: '20px', maxHeight: '600px', overflow: 'auto' }}>
                  {listOfEquipments}
                </CCol>

                {/* old active code */}
                {/* <table className="table" style={{ marginTop: '20px' }}>
                  <thead>
                    <tr>
                      <th>Request</th>
                      <th>Equipment Category</th>
                      <th>Model</th>
                      <th>Location</th>
                      <th>Date Start</th>
                      <th>Date End</th>
                      <th>Rental</th>
                    </tr>
                  </thead>
                  <tbody>{ListEquipment}</tbody>
                </table> */}
                {/* old active code */}
                {/* old active code */}
                {/* <CCol md="12" style={{ padding: '0', marginTop: '20px' }}>
                  <nav className="float-left">
                    <ul className="pagination">
                      <li className="page-item">
                        <a onClick={() => action('prev')} className="page-link">
                          Prev
                        </a>
                      </li>
                      {PaginateButton}
                      <li className="page-item">
                        <a onClick={() => action('next')} className="page-link">
                          Next
                        </a>
                      </li>
                    </ul>
                  </nav>
                </CCol> */}
                {/* old active code */}
                {/* <CRow style={{ marginTop: "20px" }}>
                  <CCol xs="12" sm="12" md="12" lg="4">
                    <CButton
                      variant="outline"
                      color="aimhi-textlight-neutral"
                      size="sm"
                      onClick={() => setModal(!modal)}
                    >
                      Add New Equipment
                    </CButton>
                  </CCol>
                  <CCol xs="12" sm="12" md="12" lg="6"></CCol>
                  <CCol xs="12" sm="12" md="12" lg="2">
                    <CButton
                      block
                      color="aimhi-primary"
                      size="sm"
                      // style={{ fontSize: "9px", height: "30px" }}
                      onClick={() => createarequest()}
                    >
                      CREATE REQUEST
                    </CButton>
                  </CCol>
                </CRow> */}
              </CCol>
            )}
            {active == 2 && (
              <CCol xs="12" sm="12" md="12" style={{ padding: '0px' }}>
                <CCol xs="12" sm="12" md="12" lg="12" style={{ marginTop: '20px' }}>
                  <CRow>
                    <CCol xs="12" sm="12" md="12" lg="8"></CCol>
                    <CCol xs="12" sm="12" md="12" lg="2">
                      {/**
                      {permission["create"][0] == 1 && (
                        <CButton
                          variant="outline"
                          color="aimhi-textlight-neutral"
                          size="sm"
                          onClick={() => setModal(!modal)}
                          block
                        >
                          Add Equipment
                        </CButton>
                      )}
                      **/}
                    </CCol>
                    <CCol xs="12" sm="12" md="12" lg="2">
                      {(permission['update'][0] == 1 || permission['create'][0] == 1) && (
                        <CButton block color="aimhi-primary" size="sm" onClick={() => createarequest('Fuel')}>
                          Create request
                        </CButton>
                      )}
                    </CCol>
                  </CRow>
                </CCol>
                <table className="table" style={{ marginTop: '10px' }}>
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Total Ordered</th>
                      <th>Quantity (Liters)</th>
                      <th>Unit Price</th>
                      <th>Total Price</th>
                      <th>Supplier</th>
                      <th>Deployment Date</th>
                      <th>Notes</th>
                    </tr>
                  </thead>
                  <tbody>{FuelTable}</tbody>
                </table>
              </CCol>
            )}

            {active == 3 && (
              <table className="table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>ER Number</th>
                    <th>Category</th>
                    <th>Preparer</th>
                    <th>Request Date</th>
                    <th>Remarks</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{ErHistory}</tbody>
              </table>
            )}

            {active == 4 && (
              <CCol style={{ padding: '0px' }}>
                <CRow>
                  <CCol xs="12" sm="12" md="12" lg="12" style={{ marginTop: '20px' }}>
                    <CRow>
                      <CCol xs="12" sm="12" md="12" lg="8"></CCol>
                      <CCol xs="12" sm="12" md="12" lg="2">
                        {permission['update'][0] == 1 && (
                          <CButton variant="outline" color="aimhi-textlight-neutral" size="sm" block onClick={handlePrint}>
                            <span>Print request</span>
                          </CButton>
                        )}
                      </CCol>
                      <CCol xs="12" sm="12" md="12" lg="2">
                        {permission['update'][0] == 1 && (
                          <CButton variant="outline" color="aimhi-textlight-neutral" size="sm" block onClick={() => setmodalEmail(true)}>
                            <span>Email request</span>
                          </CButton>
                        )}
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol xs="12" sm="12" md="12" lg="12">
                    <PrintEquipmentRequest
                      ref={componentRef}
                      equipment={equipmentRequest}
                      fuel={fuelRequest}
                      category={printCategory}
                      project_name={props.project_name}
                      project_id={props.id}

                      // pr_num={printPrNum}
                      // er_number={erNumber}
                      // notes={notes}
                      // approver={approver}
                      // date_approved={dateApproved}
                      // preparer={preparer}
                      // position={position}
                      // preparer_position={preparerPosition}
                    />
                  </CCol>
                </CRow>
              </CCol>
            )}
          </CTabs>
        </CCol>

        <CCol xs="12" sm="12" md="12" lg="12" xl="3">
          {active != 4 && <ThingsToConsider />}
        </CCol>
      </CRow>

      <CModal show={modal} onClose={() => setModal(!modal)} size="lg" style={{ borderRadius: '10px' }}>
        <CModalHeader closeButton style={{ borderColor: 'white' }}>
          <CModalTitle>Add Equipment</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Equipment Category</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <CreatableSelect value={categoryValue} options={modalCategoryList} onChange={(opt, meta) => EquipmentOnchange(opt, meta)} onCreateOption={(opt, meta) => createOptionEquipment(opt, meta)} />
                </td>
              </tr>
            </tbody>
          </table>
        </CModalBody>
        <CModalFooter style={{ borderColor: 'white' }}>
          <CCol md="4" sm="4" lg="4" xl="4">
            <CButton
              variant="outline"
              block
              color="aimhi-textlight-neutral"
              size="sm"
              onClick={() => subnewequipment()}
              // disabled={!description || !dateNeeded || !duration}
            >
              Submit
            </CButton>
          </CCol>
        </CModalFooter>
      </CModal>

      <CModal show={modalEmail} onClose={() => setmodalEmail(!modalEmail)} style={{ borderRadius: '5px' }}>
        <CModalHeader closeButton style={{ borderColor: 'white' }}>
          <CModalTitle>SEND E-MAIL</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CCol md="12" sm="12" lg="12" xl="12">
            <CFormGroup row>
              <CLabel sm="2" col="sm" htmlFor="input-small">
                TO:
              </CLabel>
              <CCol sm="6">
                <CInput size="sm" type="email" id="input-small" className="input-sm" placeholder="example@gmail.com" value={email} onChange={(e) => setemail(e.target.value)} />
              </CCol>
            </CFormGroup>
          </CCol>
        </CModalBody>
        <CModalFooter style={{ borderColor: 'white' }}>
          <CCol md="4" sm="4" lg="4" xl="4">
            <CButton block color="aimhi-primary" size="sm" onClick={() => send()} disabled={email == ''}>
              SEND
            </CButton>
          </CCol>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default Equipment;
