import { Alert } from "@coreui/coreui";
import $axios from 'src/utils/axios';
import IP from "src/StoreGlobal/IP";

let companyId = parseInt(localStorage.getItem("company_id"));
let accessToken = localStorage.getItem("token");
let user_id = parseInt(localStorage.getItem("user_id"));

export const EquipmentRequestHistoryDetailsEdit = (data) => {
  return $axios.put(`equipment/request-history/details/edit`, {
    ...data
  })
};

export const EquipmentRequestHistoryEdit = (companyId, projectId, data) => {
  return $axios.put(`equipment/request-history/${companyId}/${projectId}`)
}

export const GetTotalFuelOrder = (projectId) => {
  return $axios.get(`equipment/fuel/total-order/${projectId}`)
}
