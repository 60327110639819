import React, { useState, useEffect } from 'react';
import { CButton, CCard, CCardBody, CCardFooter, CCol, CRow, CModal, CModalBody, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react';

import 'react-circular-progressbar/dist/styles.css';
import './ProjectDetails.css';
import axios from 'axios';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Swal from 'sweetalert2/dist/sweetalert2.js';
import IP from '../../StoreGlobal/IP';
import 'react-datepicker/dist/react-datepicker.css';

import { FaTimes, FaReply, FaPaperclip, FaImage, FaFileWord, FaFileExcel, FaFilePowerpoint, FaFilePdf, FaFileCsv, FaEllipsisV, FaFileAlt, FaPencilAlt, FaTrashAlt } from 'react-icons/fa';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import likeIcon from 'src/assets/img/like.png';
import unlikeIcon from 'src/assets/img/unlike.png';
import { getUpdates, postUpdates, postReply, posLike, deletePost, getUsers, Update } from 'src/request/Updates';

import { MentionsInput, Mention } from 'react-mentions';

const defaultStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      minHeight: 63,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      // border: "1px solid silver",
    },
  },

  '&singleLine': {
    display: 'inline-block',
    width: 180,

    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      border: '2px inset',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};

const defaultMentionStyle = {
  backgroundColor: '#cee4e5',
};

const Updates = (props) => {
  let company_id = parseInt(localStorage.getItem('company_id'));
  let user_id = parseInt(localStorage.getItem('user_id'));

  let now = Date.now();
  let date = new Date(now).toLocaleString('en-US', {
    hour12: false,
  });

  const [read, setRead] = useState('read-more');
  const [value, setValue] = React.useState('');
  const [file, setFile] = useState([]);
  const [docs, setdocs] = useState([]);
  const [fileReply, setFileReply] = useState([]);
  const [docsReply, setdocsReply] = useState([]);
  const [showImage, setShowImage] = useState(false);
  const [imagePreview, setImagePreview] = useState();
  const [showPost, setShowPost] = useState(false);
  const [showReply, setShowReply] = useState();
  const [showReplyEdit, setShowReplyEdit] = useState(-1);
  const [notes, setnotes] = useState('');
  const [imageUpload, setImageUpload] = useState([]);
  const [fileUpload, setFileUpload] = useState([]);
  const [profilePic, setProfilePic] = useState();
  const [fullName, setFullName] = useState();
  const [updatesData, setUpdatesData] = useState([]);
  const [hoverDelet, setHoverDelete] = useState();

  const [replyfileObj, setReplyFileObject] = useState([]);
  const [replydocsFileObj, setReplydocsFileObj] = useState([]);
  const [buttonChange, setButtonChange] = useState('save');
  const [replyButtonChange, setReplyButtonChange] = useState('save');
  const [replyValue, setReplyValue] = useState();
  const [removeImg, setRemoveImg] = useState([]);
  const [removeFile, setRemoveFile] = useState([]);
  const [removeImgReply, setRemoveImgReply] = useState([]);
  const [removeFileReply, setRemoveFileReply] = useState([]);

  const [postId, setPostId] = useState();
  const [replyId, setReplyId] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState();
  const [users, setUsers] = useState('');

  useEffect(() => {
    let user_id = parseInt(localStorage.getItem('user_id'));
    GetUsersById(user_id);
    getPost(props.id, limit);
    GetAllUsers();
  }, []);

  const fetchUsers = (query, callback) => {
    if (!query) {
      return;
    }

    setTimeout(() => {
      const filteredUsers = users.filter((user) => user.display.toLowerCase().includes(query));
      callback(filteredUsers);
    }, 1000);
  };

  const GetAllUsers = () => {
    getUsers().then((res) => {
      if (res.data.status == 1) {
        let data = res.data.response;
        let arr = [];
        data.forEach((user) => {
          arr.push({
            id: user.value,
            display: user.label,
          });
        });

        setUsers(arr);
      }
    });
  };

  const getPost = (projectId, limit) => {
    getUpdates(projectId, page, limit).then(function (response) {
      if (response.data.status == 1) {
        console.log(response);
        setUpdatesData(response.data.response.data);
        setTotalItems(response.data.response.total_items);
      }
    });
  };

  const GetUsersById = (user_id) => {
    axios
      .post(IP + 'account/user/info', {
        user_id,
        headers: {
          'Content-Type': 'application/json',
          // "Drive-Token": token,
        },
      })
      .then(function (response) {
        if (response.data.status == 1) {
          setFullName(response.data.response.full_name);

          setProfilePic(response.data.response.profile_pic);

          // setUsersList(response.data.response);
        }
      })
      .catch((error) => {
        console.log('Profile/about-me/AboutME.js', ' ', 'line 150', error);
      });
  };
  const saveUpdate = () => {
    console.log('save update');
    let data = {
      message: notes,
      date_time: date.replace(',', ''),
      company_id: company_id,
      user_id: user_id,
      project_id: props.id,
    };

    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    console.log(imageUpload);

    for (let i = 0; i < imageUpload.length; i++) {
      formData.append(`img`, imageUpload[i], imageUpload[i].name);
    }

    for (let i = 0; i < fileUpload.length; i++) {
      formData.append(`file`, fileUpload[i]);
    }

    postUpdates(formData).then(function (response) {
      if (response.data.status == 1) {
        getPost(props.id, limit);
        setImageUpload([]);
        setFileUpload([]);
        setnotes('');
        setFile([]);
        setdocs([]);
        setShowPost(false);
      }
    });
  };

  const updatePost = () => {
    console.log('update post');
    let formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('message', notes);
    formData.append('remove_img', JSON.stringify(removeImg));
    formData.append('remove_file', JSON.stringify(removeFile));

    console.log(imageUpload, 'imagez');
    for (let i = 0; i < imageUpload.length; i++) {
      console.log(imageUpload[i], 'image upload');
      formData.append(`img`, imageUpload[i], imageUpload[i].name);
    }

    for (let i = 0; i < fileUpload.length; i++) {
      formData.append(`file`, fileUpload[i]);
    }
    Update(formData, 'post', postId).then((response) => {
      if (response.data.status == 1) {
        getPost(props.id, limit);
        setImageUpload([]);
        setFileUpload([]);
        setnotes('');
        setFile([]);
        setdocs([]);
        setShowPost(false);
      }
    });
  };

  const updateReply = () => {
    let data = {
      message: replyValue,
    };
    let formData = new FormData();
    formData.append('user_id', user_id);
    formData.append('message', replyValue);
    formData.append('remove_img', JSON.stringify(removeImgReply));
    formData.append('remove_file', JSON.stringify(removeFileReply));

    for (let i = 0; i < replyfileObj.length; i++) {
      console.log(replyfileObj[i].name);
      formData.append(`img`, replyfileObj[i], replyfileObj[i].name);
    }

    for (let i = 0; i < replydocsFileObj.length; i++) {
      formData.append(`file`, replydocsFileObj[i]);
    }

    Update(formData, 'reply', replyId).then((response) => {
      console.log(response);
      if (response.data.status == 1) {
        getPost(props.id, limit);
        setReplyValue('');
        setReplyFileObject([]);
        setReplydocsFileObj([]);
        setRemoveImgReply([]);
        setRemoveFileReply([]);
        setShowReplyEdit(-1);
        setShowPost(false);
      }
    });
  };

  const ReplyUpdate = (pos_id) => {
    const data = {
      project_id: props.id,
      company_id: company_id,
      post_id: pos_id,
      message: replyValue,
      date_time: date.replace(',', ''),
      user_id: user_id,
    };

    let formData = new FormData();
    formData.append('data', JSON.stringify(data));

    for (let i = 0; i < replyfileObj.length; i++) {
      formData.append(`img`, replyfileObj[i], replyfileObj[i].name);
    }

    for (let i = 0; i < replydocsFileObj.length; i++) {
      formData.append(`file`, replydocsFileObj[i]);
    }

    postReply(formData).then(function (response) {
      if (response.data.status == 1) {
        formData = new FormData();
        setReplyFileObject([]);
        setReplydocsFileObj([]);
        setFileReply([]);
        setdocsReply([]);
        setReplyValue('');
        setShowReply(-1);
        getPost(props.id, limit);
      } else {
        Swal.fire({
          icon: 'error',
          title: response.data.response,
          text: response.data.description,
          confirmButtonColor: '#23303D',
        });
      }
    });
  };

  const EditPost = (post_id) => {
    setPostId(post_id);

    let arr = [...updatesData];
    let img_arr = [];
    let file_arr = [];

    let Index = arr.findIndex((a) => a.post_id == post_id);

    for (var i in arr[Index].img) {
      img_arr.push({
        name: arr[Index].img[i]['name'],
        link: arr[Index].img[i]['link'],
      });
    }

    for (var i in arr[Index].file) {
      file_arr.push(arr[Index].file[i]['name']);
    }

    setdocs(file_arr);

    setFile(img_arr);
    setShowPost(true);
    // setnotes(arr[Index].note);
  };

  const editReply = (val, rep_id, post_id) => {
    setReplyId(rep_id);
    setPostId(post_id);
    setShowReplyEdit(val);
    let arr = [...updatesData];
    let img_arr = [];
    let file_arr = [];
    let p_index = arr.findIndex((a) => a.post_id == post_id);

    let r_index = arr[p_index]['reply'].findIndex((a) => a.reply_id == rep_id);

    setReplyValue(arr[p_index]['reply'][r_index]['reply']);

    for (var i in arr[p_index]['reply'][r_index]['img']) {
      img_arr.push(arr[p_index]['reply'][r_index]['img'][i]);
    }

    for (var i in arr[p_index]['reply'][r_index]['file']) {
      file_arr.push(arr[p_index]['reply'][r_index]['file'][i]);
    }

    setFileReply(img_arr);
    setdocsReply(file_arr);
  };

  // const UpdatePost = () => {
  //   // alert("hello");
  //   let data = {
  //     post_id: postId,
  //     note: notes,
  //     date_updated: date.replace(",", ""),
  //     company_id: company_id,
  //     user_id: user_id,
  //     project_id: props.id,
  //   };

  //   let formData = new FormData();
  //   formData.append("data", JSON.stringify(data));

  //   for (let i = 0; i < imageUpload.length; i++) {
  //     console.log(imageUpload[i]);
  //     formData.append(`img`, imageUpload[i]);
  //   }

  //   for (let i = 0; i < fileUpload.length; i++) {
  //     console.log(fileUpload[i]);
  //     formData.append(`file`, fileUpload[i]);
  //   }

  //   // for (var pair of formData.entries()) {
  //   //   console.log(pair[0] + ", " + pair[1]);
  //   // }

  //   //api post here
  //   axios
  //     .post(IP + "project/updates/edit", formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     })
  //     .then((res) => {
  //       if (res.data.status == 1) {
  //         getPost(company_id, props.id);
  //       } else {
  //         Swal.fire({
  //           icon: "error",
  //           title: res.data.response,
  //           text: res.data.description,
  //           confirmButtonColor: "#23303D",
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log();
  //     });
  // };

  const removeUpdates = (post_id, category) => {
    Swal.fire({
      icon: 'question',
      title: `Do you want to remove this ${category}?`,
      showCancelButton: true,
      confirmButtonColor: '#23303D',
      confirmButtonText: 'Yes',
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        deletePost(post_id, category).then((res) => {
          if (res.data.status == 1) {
            getPost(props.id, limit);
            Swal.fire({
              icon: 'success',
              title: `${category}  Deleted`,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        });
      }
    });
  };

  const like = (post_id, user_id, val) => {
    let data = {
      post_id,
      user_id,
      action: val,
    };
    posLike(data).then((res) => {
      getPost(props.id, limit);
    });
  };

  const upload = (e) => {
    let new_arr = [];
    let arr = [...imageUpload];
    let img_arr = [...file];

    new_arr.push(e.target.files);

    for (let i = 0; i < e.target.files.length; i++) {
      arr.push(e.target.files[i]);
      // var controller = new AbortController();

      // var options = {
      //   // other options here
      //   signal: controller.signal,
      // };
      // imageCompression(e.target.files[i], options)
      //   .then(function (compressedFile) {
      //     arr.push(compressedFile);
      //   })
      //   .catch(function (error) {
      //     console.log(error.message); // output: I just want to stop
      //   });

      // // simulate abort the compression after 1.5 seconds
      // setTimeout(function () {
      //   controller.abort(new Error("I just want to stop"));
      // }, 1500);
    }

    setImageUpload(arr);

    for (let i = 0; i < new_arr[0].length; i++) {
      console.log();
      img_arr.push({
        name: new_arr[0][i].name,
        link: URL.createObjectURL(new_arr[0][i]),
      });
    }

    setFile(img_arr);
  };

  const uploadFiles = (e) => {
    let new_arr = [];
    let arr = [...fileUpload];
    let arr_docs = [...docs];

    new_arr.push(e.target.files);
    for (var i in e.target.files) {
      arr.push(e.target.files[i]);
    }
    setFileUpload(arr);

    for (let i = 0; i < new_arr[0].length; i++) {
      arr_docs.push(new_arr[0][i]['name']);
    }

    setdocs(arr_docs);
  };

  const uploadFilesReply = (e) => {
    let new_arr = [];
    let arr = [...replydocsFileObj];
    let arr_docs = [...docsReply];

    new_arr.push(e.target.files);
    for (var i in e.target.files) {
      arr.push(e.target.files[i]);
    }

    setReplydocsFileObj(arr);

    for (let i = 0; i < new_arr[0].length; i++) {
      arr_docs.push({ name: new_arr[0][i]['name'], link: '' });
    }

    setdocsReply(arr_docs);
  };

  const uploadReply = (e) => {
    let new_arr = [];
    let arr = [...replyfileObj];
    let arr_img = [...fileReply];
    new_arr.push(e.target.files);
    for (let i = 0; i < e.target.files.length; i++) {
      arr.push(e.target.files[i]);
      // var controller = new AbortController();

      // var options = {
      //   // other options here
      //   signal: controller.signal,
      // };
      // imageCompression(e.target.files[i], options)
      //   .then(function (compressedFile) {
      //     arr.push(compressedFile);
      //   })
      //   .catch(function (error) {
      //     console.log(error.message); // output: I just want to stop
      //   });

      // // simulate abort the compression after 1.5 seconds
      // setTimeout(function () {
      //   controller.abort(new Error("I just want to stop"));
      // }, 1500);
    }
    setReplyFileObject(arr);
    for (let i = 0; i < new_arr[0].length; i++) {
      arr_img.push({
        name: new_arr[0][i]['name'],
        link: URL.createObjectURL(new_arr[0][i]),
      });
    }

    setFileReply(arr_img);
  };

  const clickImagePreview = (link) => {
    setShowImage(true);
    setImagePreview(link);
  };

  const loadMore = () => {
    setLimit(limit + 10);
    getPost(props.id, limit + 10);
  };

  const htmlchange = (e) => {
    console.log(e);
  };

  const remove = (name) => {
    let arr = [...file];
    let imgarr = [...imageUpload];
    let remove = [...removeImg];

    console.log(imgarr);

    let data = arr.filter((a) => a.name != name);

    let findIndex = imgarr.findIndex((a) => a.name == name);
    if (findIndex == -1) {
      remove.push(name);
      setRemoveImg(remove);
      console.log(remove);
    }

    if (imgarr.length > 0) {
      let dataImg = imgarr.filter((a) => a.name != name);
      console.log(dataImg);
      setImageUpload(dataImg);
    }

    setFile(data);
  };

  const removeReplyImage = (name) => {
    let arr = [...replyfileObj];
    let arr_img = [...fileReply];
    let remove = [...removeImgReply];

    let filter = arr_img.filter((a) => a.name != name);

    let findIndex = arr.findIndex((a) => a.name == name);

    if (findIndex == -1) {
      remove.push(name);

      setRemoveImgReply(remove);
    }

    if (arr.length > 0) {
      let dataImg = arr.filter((a) => a.name != name);

      setReplyFileObject(dataImg);
    }

    setFileReply(filter);
  };

  const removeDocs = (name) => {
    let arr = [...fileUpload];
    let arr_docs = [...docs];
    let remove = [...removeFile];

    let filter = arr_docs.filter((a) => a != name);

    let findIndex = arr.findIndex((a) => a.name == name);

    if (findIndex == -1) {
      remove.push(name);

      setRemoveFile(remove);
    }

    if (arr.length > 0) {
      let fileData = arr.filter((a) => a.name != name);

      setFileUpload(fileData);
    }

    setdocs(filter);
  };

  const removeReplyFile = (name) => {
    let arr = [...replydocsFileObj];
    let arr_docs = [...docsReply];
    let remove = [...removeFileReply];

    let filter = arr_docs.filter((a) => a.name != name);

    let findIndex = arr.findIndex((a) => a.name == name);

    if (findIndex == -1) {
      remove.push(name);
      console.log(remove);
      setRemoveFileReply(remove);
    }

    if (arr.length > 0) {
      let fileData = arr.filter((a) => a.name != name);
      console.log(fileData);
      setReplydocsFileObj(fileData);
    }

    setdocsReply(filter);
  };

  const noteChange = (e, val) => {
    setnotes((prevValue) => e);
  };

  const ImgUpload = file.map((url, Index) => {
    return (
      <CCol md="4" key={Index} style={{ padding: '10px' }} className="div-image">
        <i className="div-icon">
          <FaTimes style={{ height: '20px', width: '20px' }} onClick={() => remove(url.name)} />
        </i>
        <img src={url.link} style={{ width: '100%', height: '100px' }} />
      </CCol>
    );
  });

  const docsPreview = docs.map((name, Index) => {
    let ex = name.split('.').pop();

    return (
      <CCol md="3" key={Index} style={{ textAlign: 'center' }} className="div-image">
        <i className="div-icon">
          <FaTimes style={{ height: '20px', width: '20px' }} onClick={() => removeDocs(name)} />
        </i>
        {(ex == 'docx' || ex == 'doc') && <FaFileWord style={{ width: '30px', height: '50px' }} />}
        {(ex == 'xlsx' || ex == 'xls') && <FaFileExcel style={{ width: '30px', height: '50px' }} />}

        {(ex == 'ppt' || ex == 'pptx') && <FaFilePowerpoint style={{ width: '30px', height: '50px' }} />}
        {ex == 'pdf' && <FaFilePdf style={{ width: '30px', height: '50px' }} />}
        {ex == 'csv' && <FaFileCsv style={{ width: '30px', height: '50px' }} />}
        {ex == 'txt' && <FaFileAlt style={{ width: '30px', height: '50px' }} />}

        <div>
          <span style={{ fontSize: '12px' }}>{name}</span>
        </div>
      </CCol>
    );
  });

  const ReplyImgUpload = fileReply.map((url, Index) => {
    return (
      <CCol md="2" key={Index} style={{ marginTop: '10px' }}>
        <i className="div-icon-reply">
          <FaTimes style={{ height: '20px', width: '20px' }} onClick={() => removeReplyImage(url.name)} />
        </i>
        <img src={url.link} style={{ width: '100%', height: '50px' }} />
      </CCol>
    );
  });

  const ReplydocsPreview = docsReply.map((name, Index) => {
    let ex = name.name.split('.').pop();

    return (
      <CCol md="2" key={Index} style={{ textAlign: 'center' }}>
        <i className="div-icon-reply">
          <FaTimes style={{ height: '20px', width: '20px' }} onClick={() => removeReplyFile(name.name)} />
        </i>
        {(ex == 'docx' || ex == 'doc') && <FaFileWord style={{ width: '20px', height: '40px' }} />}
        {(ex == 'xlsx' || ex == 'xls') && <FaFileExcel style={{ width: '20px', height: '40px' }} />}

        {(ex == 'ppt' || ex == 'pptx') && <FaFilePowerpoint style={{ width: '20px', height: '40px' }} />}
        {ex == 'pdf' && <FaFilePdf style={{ width: '20px', height: '40px' }} />}
        {ex == 'csv' && <FaFileCsv sstyle={{ width: '20px', height: '40px' }} />}

        <div>
          <span style={{ fontSize: '10px' }}>{name.name}</span>
        </div>
      </CCol>
    );
  });

  const Updates = updatesData.map((data, Index) => {
    let note_data = '';

    let str = data.note.split('\n');

    let p_index = Index;

    let arr_img = [];
    // let data_reply = [];
    // let sample =
    //   (typeof data["reply"][0] == "undefined") == false
    //     ? data.reply[0]
    //     : data["reply"];

    // data_reply.push(sample);

    // console.log(data_reply);
    let imagelength = data['img'].length;
    let row = 4;

    const img = data['img'].map((image, Index) => {
      if (imagelength == 1) {
        row = 12;
      } else if (imagelength == 2) {
        row = 6;
      }

      return (
        <CCol md={row} key={Index} style={{ padding: '5px' }}>
          <img
            src={image.link}
            style={{
              width: '100%',
              height: '100%',
              borderStyle: 'solid',
              borderWidth: '1px',
              borderColor: '#DEDEE1',
            }}
            onClick={() => clickImagePreview(image.link)}
          />
        </CCol>
      );
    });

    const file = data['file'].map((srcfile, Index) => {
      let ex = srcfile.name.split('.').pop();

      return (
        <CCol md="2" key={Index} style={{ textAlign: 'center' }}>
          {(ex == 'docx' || ex == 'doc') && <FaFileWord style={{ width: '20px', height: '40px' }} />}
          {(ex == 'xlsx' || ex == 'xls') && <FaFileExcel style={{ width: '20px', height: '40px' }} />}

          {(ex == 'ppt' || ex == 'pptx') && <FaFilePowerpoint style={{ width: '20px', height: '40px' }} />}
          {ex == 'pdf' && <FaFilePdf style={{ width: '20px', height: '40px' }} />}
          {ex == 'csv' && <FaFileCsv sstyle={{ width: '20px', height: '40px' }} />}

          <div>
            <span style={{ fontSize: '10px' }}>
              <a href={srcfile.link} target="_blank">
                {srcfile.name}
              </a>
            </span>
          </div>
        </CCol>
      );
    });

    const reply = data['reply'].map((rep, Index) => {
      let repstr = rep.reply.split('\n');
      const ReplyImg = rep['img'].map((url, Index) => {
        let src = url.link.toString();
        //hellogit as
        return (
          <CCol md="5" key={Index}>
            <img
              src={src}
              style={{
                width: '80%',
                height: '100%',
                borderRadius: '10px',
                marginLeft: '30px',
                borderStyle: 'solid',
                borderWidth: '1px',
                borderColor: '#DEDEE1',
                marginTop: '5px',
              }}
              onClick={() => clickImagePreview(url.link)}
            />
          </CCol>
        );
      });

      const Replydocs = rep['file'].map((name, Index) => {
        let ex = name.name.split('.').pop();

        return (
          <CCol md="3" key={Index} style={{ textAlign: 'center' }}>
            {(ex == 'docx' || ex == 'doc') && <FaFileWord style={{ width: '20px', height: '40px' }} />}
            {(ex == 'xlsx' || ex == 'xls') && <FaFileExcel style={{ width: '20px', height: '40px' }} />}

            {(ex == 'ppt' || ex == 'pptx') && <FaFilePowerpoint style={{ width: '20px', height: '40px' }} />}
            {ex == 'pdf' && <FaFilePdf style={{ width: '20px', height: '40px' }} />}
            {ex == 'csv' && <FaFileCsv sstyle={{ width: '20px', height: '40px' }} />}

            <div>
              <span style={{ fontSize: '10px' }} className="file_text">
                <a href={name.link} title={name.name}>
                  {name.name}
                </a>
              </span>
            </div>
          </CCol>
        );
      });

      return (
        <CCol md="12" style={{ padding: '0' }} key={Index}>
          {showReplyEdit == p_index.toString() + Index.toString() ? (
            <CCol md="12" className="fade-in">
              <div className="box">
                {profilePic == '' ? <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" className="img_comment" /> : <img src={profilePic} className="img_comment" />}

                <div style={{ marginLeft: '10px' }} className="reply-box">
                  <MentionsInput placeholder="Write an update..." style={defaultStyle} value={replyValue} onChange={(e) => setReplyValue(e.target.value)} a11ySuggestionsListLabel={'Suggested mention'}>
                    <Mention
                      trigger="@"
                      data={fetchUsers}
                      style={defaultMentionStyle}
                      // renderSuggestion={this.renderUserSuggestion}
                    />
                  </MentionsInput>
                  {/* <CTextarea
                    placeholder="Write an update1..."
                    style={{ border: "0px" }}
                    value={replyValue}
                    onChange={(e) => setReplyValue(e.target.value)}
                  ></CTextarea> */}
                  <CRow>
                    {ReplyImgUpload}
                    {ReplydocsPreview}
                  </CRow>
                </div>
              </div>
              <CRow>
                <CCol md="1"></CCol>
                <CCol md="3">
                  <label className="upload-file">
                    <input multiple type="file" name="file" id="file-input" className="upload-button upload-file" accept=".jfif,.jpg,.jpeg,.png,.gif" onChange={uploadReply} />

                    <span>
                      <FaImage /> Add Photos
                    </span>
                  </label>
                </CCol>
                <CCol md="3">
                  <label className="upload-file">
                    <input multiple type="file" name="file" id="file-input" className="upload-button upload-file" onChange={uploadFilesReply} accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.csv" />

                    <span>
                      <FaPaperclip /> Add Files
                    </span>
                  </label>
                </CCol>
                <CCol md="3"></CCol>
                <CCol md="2">
                  <CButton color="aimhi-primary" size="sm" style={{ marginTop: '20px' }} block onClick={updateReply}>
                    Reply
                  </CButton>
                </CCol>
              </CRow>
            </CCol>
          ) : (
            <CCol md="12" style={{ marginTop: '10px' }} key={Index} onMouseEnter={() => setHoverDelete(p_index.toString() + Index.toString())} onMouseLeave={() => setHoverDelete()}>
              <div className="box">
                {rep.profile_img == '' ? <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" className="img_comment" /> : <img src={rep.profile_img} className="img_comment" />}

                {/* <div style={{ marginLeft: "10px" }}>
                  <CRow style={{ marginTop: "5px", padding: "" }}>
                    {ReplyImg}
                    {Replydocs}
                  </CRow>
                </div> */}

                <div style={{ marginLeft: '10px' }}>
                  <p className="comment-style">
                    <span style={{ fontWeight: 'bold' }}>{rep.name}</span> <br></br>
                    {repstr.map((item, index) => {
                      return <p key={index}>{item.replace(/(@)\[([\w\d\s.]+)\]\(\d+\)/gm, '$1$2')}</p>;
                    })}
                  </p>
                </div>
                {hoverDelet == p_index.toString() + Index.toString() && user_id == rep.user_id && (
                  <div>
                    <CDropdown className="m-1" size="sm">
                      <CDropdownToggle className="caret">
                        <FaEllipsisV />
                      </CDropdownToggle>
                      <CDropdownMenu>
                        <CDropdownItem onClick={() => editReply(p_index.toString() + Index.toString(), rep.reply_id, data.post_id, setReplyButtonChange('update'))} size="sm">
                          <FaPencilAlt style={{ marginRight: '5px' }} />
                          <span style={{ fontWeight: 'bold' }}>Edit Post</span>
                        </CDropdownItem>
                        <CDropdownItem size="sm" onClick={() => removeUpdates(rep.reply_id, 'reply')}>
                          <FaTrashAlt style={{ marginRight: '5px' }} />
                          <span style={{ fontWeight: 'bold' }}>Move to trash</span>
                        </CDropdownItem>
                      </CDropdownMenu>
                    </CDropdown>
                  </div>
                )}
              </div>

              {ReplyImg}
              {Replydocs}
            </CCol>
          )}
        </CCol>
      );
    });
    return (
      <CCard style={{ marginTop: '20px' }} key={Index} className="post-card-shadow ">
        {/* <CCardHeader>Create Update</CCardHeader> */}
        <CCardBody>
          <CCol md="12" className="time_post">
            <CRow>
              <CCol md="6"></CCol>
              <CCol md="6">
                <CRow>
                  <CCol md="9" style={{ marginTop: '12px' }}>
                    <span>{data.date_time}</span>
                  </CCol>
                  <CCol md="3">
                    <span>
                      {user_id == data.user_id && (
                        <CDropdown className="m-1" size="sm">
                          <CDropdownToggle className="caret">
                            <FaEllipsisV />
                          </CDropdownToggle>
                          <CDropdownMenu>
                            <CDropdownItem
                              onClick={() =>
                                EditPost(
                                  data.post_id,
                                  setButtonChange('edit'),
                                  // noteChange(data.note)
                                  noteChange(data.note.replace(/\r\n/g, '\n')),
                                )
                              }
                              size="sm"
                            >
                              <FaPencilAlt style={{ marginRight: '5px' }} />
                              <span style={{ fontWeight: 'bold' }}>Edit Post</span>
                            </CDropdownItem>
                            <CDropdownItem size="sm" onClick={() => removeUpdates(data.post_id, 'post')}>
                              <FaTrashAlt style={{ marginRight: '5px' }} />
                              <span style={{ fontWeight: 'bold' }}>Move to trash</span>
                            </CDropdownItem>
                          </CDropdownMenu>
                        </CDropdown>
                      )}
                    </span>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCol>
          <CCol md="12">
            {data.profile_img == '' ? <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" className="img_post" /> : <img src={data.profile_img} className="img_post" />}

            <span className="name_post">{data.name}</span>
          </CCol>
          <CCol md="12" style={{ marginTop: '20px' }} className={read}>
            {str.map((item, index) => {
              return <p key={index}>{item.replace(/(@)\[([\w\d\s.]+)\]\(\d+\)/gm, '$1$2')}</p>;
            })}
            {/* <ReadMoreReact
              text={data.note}
              min={80}
              ideal={100}
              max={200}
              readMoreText={
                <span style={{ color: "#549761" }}>
                  Read More <FaArrowDown style={{ marginLeft: "5px" }} />
                </span>
              }
            /> */}

            <CCol md="12" style={{ marginTop: '20px', padding: '0px' }}>
              <CRow>{img}</CRow>
            </CCol>

            <CCol md="12" style={{ marginTop: '20px', padding: '0px' }}>
              <CRow>{file}</CRow>
            </CCol>
          </CCol>

          <CCol md="12" style={{ marginTop: '20px', padding: '0px' }}>
            {data.likes != 0 && (
              <span>
                <img src={likeIcon} style={{ width: '25px', height: '25px' }} />
                <span style={{ marginLeft: '5px', fontSize: '12px' }}>{data.likes}</span>
              </span>
            )}
          </CCol>
          {/* <CCol md="12" style={{ marginTop: "20px", textAlign: "center" }}>
            <span
              style={{ color: "#549764" }}
              onClick={() =>
                setRead(read == "read-more" ? "read-less" : "read-more")
              }
            >
              {read == "read-more" ? (
                <span>
                  Read Less <FaArrowUp style={{ marginLeft: "5px" }} />
                </span>
              ) : (
                <span>
                  Read More
                  <FaArrowDown style={{ marginLeft: "5px" }} />
                </span>
              )}
            </span>
          </CCol> */}
        </CCardBody>
        <CCardFooter>
          <CCol md="12">
            <CRow>
              {data.has_liked == 1 ? (
                <CCol md="6" className="font-style">
                  <img src={likeIcon} style={{ width: '20px', height: '20px' }} />
                  {/* <Icon
                    icon="noto-v1:construction-worker"
                    style={{ width: "18px", height: "18px" }}
                  /> */}
                  {/* <FaHammer
                    style={{ width: "20px", height: "20px", color: "#549764" }}
                  /> */}
                  <span
                    style={{
                      marginLeft: '5px',
                      color: '#549764',
                      marginTop: '10px',
                    }}
                    onClick={() => like(data.post_id, user_id, data.has_liked == 1 ? 'unlike' : 'like')}
                  >
                    Unlike
                  </span>
                </CCol>
              ) : (
                <CCol md="6" className="font-style">
                  <img src={unlikeIcon} style={{ width: '20px', height: '20px' }} />
                  <span style={{ marginLeft: '5px', marginTop: '10px' }} onClick={() => like(data.post_id, user_id, data.has_liked == 1 ? 'unlike' : 'like')}>
                    Like
                  </span>
                </CCol>
              )}

              <CCol md="6" className="font-style" onClick={() => setShowReply(Index)}>
                <FaReply className="icon-size" />
                <span style={{ marginLeft: '5px' }}>Reply</span>
              </CCol>
            </CRow>
          </CCol>
        </CCardFooter>
        <CCardFooter>
          {showReply == Index && (
            <CCol md="12" className="fade-in">
              <div className="box">
                {profilePic == '' ? <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" className="img_comment" /> : <img src={profilePic} className="img_comment" />}

                <div style={{ marginLeft: '10px' }} className="reply-box">
                  <MentionsInput placeholder="Write an update..." style={defaultStyle} value={replyValue} onChange={(e) => setReplyValue(e.target.value)} a11ySuggestionsListLabel={'Suggested mention'}>
                    <Mention
                      trigger="@"
                      data={fetchUsers}
                      style={defaultMentionStyle}
                      // renderSuggestion={this.renderUserSuggestion}
                    />
                  </MentionsInput>
                  {/* <CTextarea
                    placeholder="Write an update2..."
                    style={{ border: "0px" }}
                    value={replyValue}
                    onChange={(e) => setReplyValue(e.target.value)}
                  ></CTextarea> */}
                  <CRow>
                    {ReplyImgUpload}
                    {ReplydocsPreview}
                  </CRow>
                </div>
              </div>
              <CRow>
                <CCol md="1"></CCol>
                <CCol md="3">
                  <label className="upload-file">
                    <input multiple type="file" name="file" id="file-input" className="upload-button upload-file" accept=".jfif,.jpg,.jpeg,.png,.gif" onChange={uploadReply} />

                    <span>
                      <FaImage /> Add Photos
                    </span>
                  </label>
                </CCol>
                <CCol md="3">
                  <label className="upload-file">
                    <input multiple type="file" name="file" id="file-input" className="upload-button upload-file" onChange={uploadFilesReply} accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.csv" />

                    <span>
                      <FaPaperclip /> Add Files
                    </span>
                  </label>
                </CCol>
                <CCol md="3"></CCol>
                <CCol md="2">
                  <CButton color="aimhi-primary" size="sm" style={{ marginTop: '20px' }} block onClick={() => ReplyUpdate(data.post_id)}>
                    Reply
                  </CButton>
                </CCol>
              </CRow>
            </CCol>
          )}

          {reply}
        </CCardFooter>
      </CCard>
    );
  });

  return (
    <>
      <CRow>
        <CCol xs="6" sm="6" md="6" lg="6" xl="6" style={{ marginTop: '20px' }}>
          <CCol md="12" style={{ marginTop: '20px', padding: '0px' }}>
            {showPost == false && (
              <CCard
                onClick={() => {
                  setShowPost(true);
                  noteChange('');
                  setButtonChange('save');
                }}
                className="post-card-shadow"
              >
                <CCardBody>
                  <CCol md="12">
                    <CRow>
                      {profilePic == '' ? <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" className="img_post" /> : <img src={profilePic} className="img_post" />}

                      <div className="write-post">
                        <span>Write an update...</span>
                      </div>
                    </CRow>
                  </CCol>
                </CCardBody>
              </CCard>
            )}
          </CCol>
          {Updates}
        </CCol>
        <CCol md="12">
          <CRow>
            <CCol md="5"></CCol>
            <CCol md="2" style={{ textAlign: 'center' }}>
              <span>
                Viewing {limit > totalItems ? totalItems : limit} of {totalItems} project(s)
              </span>
              {limit <= totalItems && (
                <CButton color="aimhi-textlight-neutral" size="sm" variant="outline" block onClick={loadMore}>
                  Load More
                </CButton>
              )}
            </CCol>
            <CCol md="5"></CCol>
          </CRow>
        </CCol>
      </CRow>

      <CModal show={showPost} onClose={() => setShowPost(!showPost)} style={{ borderRadius: '5px' }}>
        <CModalBody style={{ padding: '5px' }}>
          <CCol md="12" sm="12" lg="12" xl="12" style={{ padding: '0px' }}>
            <CCard className="fade-in">
              <CCardBody>
                <CCol md="12">
                  {profilePic == '' ? <img src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" className="img_post" /> : <img src={profilePic} className="img_post" />}

                  <span className="name_post">{fullName}</span>
                </CCol>
                <CCol md="12" style={{ padding: '0px', marginTop: '20px' }}>
                  <CRow></CRow>
                  {/*<CTextarea*/}
                  {/*  className="notes"*/}
                  {/*  placeholder="Write an update..."*/}
                  {/*  style={{ border: "0px", height: '40rem' }}*/}
                  {/*  value={notes ? notes : ""}*/}
                  {/*  onChange={(e, val) => {*/}
                  {/*    console.log('Write an update e', e.target.value);*/}
                  {/*    noteChange(e.target.value);*/}
                  {/*  }}*/}
                  {/*  a11ySuggestionsListLabel={"Suggested mention"}*/}
                  {/*>*/}
                  {/*  <Mention*/}
                  {/*    trigger="@"*/}
                  {/*    data={fetchUsers}*/}
                  {/*    style={defaultMentionStyle}*/}
                  {/*  />*/}
                  {/*</CTextarea>*/}
                  <MentionsInput
                    className="notes"
                    placeholder="Write an update..."
                    style={defaultStyle}
                    value={notes}
                    onChange={(e) => {
                      noteChange(e.target.value);
                    }}
                    a11ySuggestionsListLabel={'Suggested mention'}
                  >
                    <Mention
                      trigger="@"
                      data={fetchUsers}
                      style={defaultMentionStyle}
                      // renderSuggestion={this.renderUserSuggestion}
                    />
                  </MentionsInput>
                </CCol>
                <CCol md="12" style={{ padding: '0px', marginTop: '20px' }}>
                  <CRow>{ImgUpload}</CRow>
                </CCol>
                <CCol md="12" style={{ padding: '0px', marginTop: '20px' }}>
                  <CRow>{docsPreview}</CRow>
                </CCol>
                <CCol md="12" style={{ padding: '0px' }}>
                  <CRow>
                    <CCol md="4">
                      <label className="upload-file">
                        <input multiple type="file" name="file" id="file-input" className="upload-button upload-file" accept=".jfif,.jpg,.jpeg,.png,.gif" onChange={upload} />

                        <span>
                          <FaImage /> Add Photos
                        </span>
                      </label>
                    </CCol>
                    <CCol md="3">
                      <label className="upload-file">
                        <input multiple type="file" name="file" id="file-input" className="upload-button upload-file" onChange={uploadFiles} accept=".xlsx,.xls,.doc, .docx,.ppt, .pptx,.txt,.pdf,.csv" />

                        <span>
                          <FaPaperclip /> Add Files
                        </span>
                      </label>
                    </CCol>
                    <CCol md="2"></CCol>
                    <CCol md="3">
                      {buttonChange == 'save' ? (
                        <CButton color="aimhi-textlight-neutral" size="sm" block onClick={saveUpdate}>
                          Update
                        </CButton>
                      ) : (
                        <CButton color="aimhi-textlight-neutral" size="sm" block onClick={updatePost}>
                          Update
                        </CButton>
                      )}
                    </CCol>
                  </CRow>
                </CCol>
              </CCardBody>
            </CCard>
          </CCol>
        </CModalBody>
      </CModal>
      <CModal show={showImage} onClose={() => setShowImage(!showImage)} style={{ borderRadius: '5px' }} size="lg">
        <CModalBody>
          <CCol md="12">
            <img src={imagePreview} style={{ height: '100%', width: '100%' }} />
          </CCol>
        </CModalBody>
      </CModal>
    </>
  );
};

export default Updates;
